import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer } from 'vaul';
import styled from 'styled-components';

const InteractiveOverlay = styled(Drawer.Overlay)`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  cursor: pointer;
`;

const DrawerContent = styled(Drawer.Content)`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh);
  width: 50%;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  padding: 8px;
  z-index: 1001;
  box-sizing: border-box;
  pointer-events: auto;
  max-width: 560px;
  outline: none;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

const DrawerInnerContent = styled.div<{ $background?: string }>`
  background-color: white;
  border-radius: 6px;
  flex: 1;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.5), inset 0 1px 0 rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
  ${({ $background }) => $background && `background: ${$background};`}
  background-color: rgba(248, 247, 246, 1);
`;

const DrawerTextContent = styled.div`
  max-width: 768px;
  margin: 0 auto;
  height: 100%;
`;

interface DrawerChildProps {
  onPopoverOpenChange?: (isOpen: boolean) => void;
  [key: string]: unknown;
}

export interface BaseDrawerProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  children: React.ReactNode;
  onOverlayClick?: (event: React.MouseEvent) => void;
  background?: string;
  modal?: boolean;
  direction?: 'left' | 'right' | 'top' | 'bottom';
  preventClose?: boolean;
  className?: string;
  contentClassName?: string;
  innerContentClassName?: string;
  ignoreClickOutsideClasses?: string[];
}

const BaseDrawer: React.FC<BaseDrawerProps> = ({
  isOpen,
  onOpenChange,
  children,
  onOverlayClick,
  background,
  modal = false,
  direction = 'right',
  preventClose = false,
  className,
  contentClassName,
  innerContentClassName,
  ignoreClickOutsideClasses = []
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);

  const handleOpenChange = useCallback((open: boolean) => {
    if (!preventClose && !isAnyPopoverOpen) {
      onOpenChange(open);
    }
  }, [isAnyPopoverOpen, onOpenChange, preventClose]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen || isAnyPopoverOpen || preventClose) return;

      const clickedElement = event.target as Element;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);
      const isClickOnPopover = clickedElement.closest('.entity-picker-popover') !== null;
      const isClickOnIgnoredElement = ignoreClickOutsideClasses.some(
        className => clickedElement.closest(`.${className}`) !== null
      );

      if (!isClickInsideDrawer && !isClickOnPopover && !isClickOnIgnoredElement) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, isAnyPopoverOpen, handleOpenChange, preventClose, ignoreClickOutsideClasses]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen && !isAnyPopoverOpen && !preventClose) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, handleOpenChange, isAnyPopoverOpen, preventClose]);

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={handleOpenChange}
      direction={direction}
      modal={modal}
    >
      <Drawer.Portal>
        <InteractiveOverlay 
          className={className} 
          onClick={onOverlayClick || (() => handleOpenChange(false))} 
        />
        <DrawerContent className={contentClassName}>
          <DrawerInnerContent 
            ref={drawerRef} 
            $background={background}
            className={innerContentClassName}
          >
            <DrawerTextContent>
              {React.Children.map(children, child => 
                React.isValidElement(child) 
                  ? React.cloneElement(child as React.ReactElement<DrawerChildProps>, { 
                      onPopoverOpenChange: setIsAnyPopoverOpen,
                    })
                  : child
              )}
            </DrawerTextContent>
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default BaseDrawer; 