import React from 'react';
import { Drawer } from 'vaul';
import styled from 'styled-components';
import { Close12 } from '../../../shared/components/Icon';
import Button from '../../../shared/components/Button';

const DrawerPortalContent = styled(Drawer.Content)`
  pointer-events: auto;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 420px;
  height: 100vh;
  background: transparent;
  z-index: 1000;
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.5), inset 0 1px 0 rgba(255, 255, 255, 0.75);
  border-radius: 6px;
  background-color: rgba(248, 247, 246, 1);
  height: 100%;
  position: relative;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
  display: flex;
  flex-direction: column;
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 999;
`;

const DrawerHeader = styled.div`
  padding: 48px 48px 24px 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const DrawerTitle = styled.h2`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const DrawerContent = styled.div`
  padding: 24px 48px;
  flex: 1;
  overflow-y: auto;
`;

const PaymentFormContainer = styled.div`
  .StripeElement {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
  }
`;

const InvoiceAmount = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

const PaymentButton = styled(Button)<{ $isPaid: boolean }>`
  background-color: ${props => props.$isPaid ? 'transparent' : '#FFFFFF'};
  padding: 8px 16px;
  text-align: center;
  justify-content: center;
  display: flex;
  border: ${props => props.$isPaid ? '2px solid #45a049' : '0.5px solid rgba(0, 0, 0, 0.05)'};
  cursor: ${props => props.$isPaid ? 'default' : 'pointer'};
  font-size: 14px;
  margin-top: 24px;
  width: 100%;
  font-weight: 500;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  transition: box-shadow 0.1s ease, transform 0.1s ease;
  color: ${props => props.$isPaid ? '#45a049' : 'rgba(0,0,0,0.8)'};

  &:hover {
    box-shadow: 0px 2px 1px hsla(0, 0%, 0%, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.05);
    background-color: #FFFFFF;
    transform: ${props => props.$isPaid ? 'none' : 'translateY(-1px)'};
  }

  &:active {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
    transform: ${props => props.$isPaid ? 'none' : 'translateY(0) scale(0.99)'};
  }

  &:disabled {
    background-color: ${props => props.$isPaid ? 'transparent' : '#e0e0e0'};
    border: ${props => props.$isPaid ? '1.5px solid #45a049' : '0.5px solid rgba(0, 0, 0, 0.05)'};
    color: ${props => props.$isPaid ? '#45a049' : 'rgba(0,0,0,0.3)'};
    box-shadow: none;

    &:hover {
      transform: none;
    }
  }
`;

const ErrorMessage = styled.div`
  color: #ff4d4f;
  margin-top: 10px;
  font-size: 14px;
`;

interface PublicInvoiceDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  amount: number;
  currency?: string;
  isPaid?: boolean;
  isProcessing?: boolean;
  error?: string | null;
  onSubmit: (event: React.FormEvent) => Promise<void>;
  children: React.ReactNode;
}

const formatCurrency = (amount: number, currency: string = 'USD') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

const PublicInvoiceDrawer: React.FC<PublicInvoiceDrawerProps> = ({
  isOpen,
  setIsOpen,
  title,
  amount,
  currency = 'USD',
  isPaid = false,
  isProcessing = false,
  error = null,
  onSubmit,
  children,
}) => {
  const handleClose = () => {
    if (!isProcessing) {
      setIsOpen(false);
    }
  };

  return (
    <Drawer.Root
      open={isOpen}
      onOpenChange={setIsOpen}
      direction="right"
      modal={false}
      shouldScaleBackground={false}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={handleClose} />
        <DrawerPortalContent className="drawer-content">
          <DrawerInnerContent>
            <DrawerHeader>
              <DrawerTitle>{title}</DrawerTitle>
              <CloseButton onClick={handleClose} disabled={isProcessing}>
                <Close12 />
              </CloseButton>
            </DrawerHeader>
            <DrawerContent>
              <form onSubmit={onSubmit}>
                <InvoiceAmount>
                  Amount Due: {formatCurrency(amount, currency)}
                </InvoiceAmount>
                <PaymentFormContainer>
                  {children}
                </PaymentFormContainer>
                <PaymentButton
                  type="submit"
                  disabled={isPaid || isProcessing}
                  $isPaid={isPaid}
                >
                  {isPaid ? 'Invoice Paid' : isProcessing ? 'Processing...' : `Pay ${formatCurrency(amount, currency)}`}
                </PaymentButton>
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </form>
            </DrawerContent>
          </DrawerInnerContent>
        </DrawerPortalContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default PublicInvoiceDrawer; 