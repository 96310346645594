import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

interface PaymentFormProps {
  onPaymentSuccess: () => Promise<void>;
  onError: (error: string) => void;
  onProcessingChange: (isProcessing: boolean) => void;
  onPaymentElementReady: () => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  onPaymentSuccess,
  onError,
  onProcessingChange,
  onPaymentElementReady,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isReady, setIsReady] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements || !isReady) {
      return;
    }

    onProcessingChange(true);

    try {
      const { error: submitError, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-success`,
        },
        redirect: 'if_required',
      });

      if (submitError) {
        onError(submitError.message ?? 'An unknown error occurred');
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        await onPaymentSuccess();
      }
    } catch (err) {
      onError('An unexpected error occurred while processing your payment');
    } finally {
      onProcessingChange(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement 
        onReady={() => {
          setIsReady(true);
          onPaymentElementReady();
        }}
        onChange={(event) => {
          if (event.complete) {
            onError('');
          } else if (event.empty) {
            onError('Please fill in your payment details');
          } else {
            onError('');
          }
        }}
      />
    </form>
  );
};

export default PaymentForm; 