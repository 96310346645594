import React, { useState, useEffect, useCallback } from "react";
import styled from 'styled-components';
import { supabase } from "../../../supabaseClient";
import { Payment } from "../../../types";
import Button from '../../../shared/components/Button';
import { Close12, Money12, Checkmark12 } from '../../../shared/components/Icon';
import DatePicker from '../../../components/DatePicker';
import { motion } from 'framer-motion';
import { paymentSchema } from '../../../schemas/payment';
import { z } from 'zod';
import BaseDrawer from '../../../shared/components/BaseDrawer';

// Add this helper function near the top of the file, after the imports
const formatAmount = (amount: number) => {
  return amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

interface PaymentsDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  payments: Payment[];
  addPayment: (payment: Omit<Payment, "id"> & { user_id: string }) => void;
  removePayment: (paymentId: string) => void;
  amount_due: number;
  organizationId: string;
  invoiceStatus: string;
}

const TitleSection = styled.div`
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding: 40px;
`;

const DetailsContainer = styled.div<{ $status: string }>`
  display: flex;
  flex-direction: column;
`;

const PaymentHistorySection = styled.div`
  padding: 32px 40px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const AmountDueLabel = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
`;

const AmountDueValue = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  margin: 4px 0 16px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

const PaymentForm = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  margin-top: 24px;
`;

const InputField = styled.div<{ fullWidth?: boolean; hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  grid-column: ${props => props.fullWidth ? '1 / -1' : 'auto'};

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  input {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: ${props => props.hasError ? '1px solid #ff4d4f' : '0'};
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px ${props => props.hasError ? '#ff4d4f' : 'rgba(0,0,0,0.5)'};
    }
  }

  .error-message {
    color: #ff4d4f;
    font-size: 12px;
    margin-top: 4px;
  }
`;

const HistoryContainer = styled.div`
`;

const SectionHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 24px;
  color: rgba(0,0,0,0.5);
`;

const HistoryItem = styled(motion.div)`
  margin-bottom: 8px;
  position: relative;

  &:hover {
    .remove-button {
      opacity: 1;
    }
  }
`;

const HistoryIcon = styled.div`
  svg path {
    fill: rgba(0,0,0,0.5);
  }
`;

const HistoryEvent = styled.div`
  font-size: 14px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;

  svg path {
    fill: rgba(0,0,0,0.5);
  }
`;

const HistoryDate = styled.div<{ $isLast: boolean }>`
  font-size: 14px;
  border-left: 1px solid ${props => props.$isLast ? 'transparent' : 'rgba(0,0,0,0.1)'};
  padding: 0 0 24px 22px;
  margin: 2px 0 0 6px;
  color: rgba(0,0,0,0.5);
`;

const EmptyStateText = styled.div`
  color: rgba(0,0,0,0.5);
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #d32f2f;
  cursor: pointer;
  padding: 6px;
  margin-left: auto;
  opacity: 0;
  transition: opacity 0.2s ease, background 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;

  svg path {
    fill: #d32f2f;
  }

  &:hover {
    background: rgba(211, 47, 47, 0.1);
  }
`;

const PaymentsDrawer: React.FC<PaymentsDrawerProps> = ({
  isOpen,
  setIsOpen,
  payments,
  addPayment,
  removePayment,
  amount_due,
  organizationId,
  invoiceStatus,
}) => {
  const [showCustomPayment, setShowCustomPayment] = useState(false);
  const [newPayment, setNewPayment] = useState<
    Omit<Payment, "id" | "user_id" | "organization_id">
  >({
    amount: amount_due || 0,
    payment_date: new Date().toISOString().split("T")[0],
    notes: "",
    invoice_id: "",
  });
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    setNewPayment((prev) => ({ ...prev, amount: amount_due || 0 }));
  }, [amount_due]);

  const validatePayment = (paymentData: Partial<Payment>) => {
    try {
      paymentSchema.parse({
        ...paymentData,
        organization_id: organizationId,
        user_id: 'temp', // Will be replaced with actual user_id before saving
      });
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          const field = err.path[0];
          if (typeof field === 'string') {
            newErrors[field] = err.message;
          }
        });
        setErrors(newErrors);
      }
      return false;
    }
  };

  const handleMarkAsPaid = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) {
      console.error("User not authenticated");
      return;
    }

    const paymentData = {
      amount: amount_due,
      payment_date: new Date().toISOString().split("T")[0],
      notes: "Marked as paid",
      invoice_id: "",
      user_id: user.id,
      organization_id: organizationId,
    };

    if (!validatePayment(paymentData)) {
      return;
    }

    addPayment(paymentData);
    setIsOpen(false);
  };

  const handleAddPayment = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) {
      console.error("User not authenticated");
      return;
    }

    const paymentData = {
      ...newPayment,
      user_id: user.id,
      organization_id: organizationId,
    };

    if (!validatePayment(paymentData)) {
      return;
    }

    addPayment(paymentData);
    setNewPayment({
      amount: amount_due,
      payment_date: new Date().toISOString().split("T")[0],
      notes: "",
      invoice_id: "",
    });
    setShowCustomPayment(false);
  };

  const handleClose = useCallback(() => {
    if (!isAnyPopoverOpen) {
      setIsOpen(false);
      setShowCustomPayment(false);
    }
  }, [isAnyPopoverOpen, setIsOpen]);

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleDateChange = (date: Date | null) => {
    setNewPayment({
      ...newPayment,
      payment_date: date ? date.toISOString().split("T")[0] : "",
    });
  };

  const handleRemovePayment = useCallback((paymentId: string) => {
    removePayment(paymentId);
  }, [removePayment]);

  const historyItemVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 }
  };

  const staggerDuration = 0.05;
  const animationDuration = 0.5;

  const background = (() => {
    switch (invoiceStatus) {
      case 'unpaid':
        return 'linear-gradient(135deg, #fff4e5 0%, rgba(255,255,255,0) 30%)';
      case 'paid':
        return 'linear-gradient(135deg, #e6f4ea 0%, rgba(255,255,255,0) 30%)';
      case 'overdue':
        return 'linear-gradient(135deg, #fde7e9 0%, rgba(255,255,255,0) 30%)';
      case 'draft':
        return 'linear-gradient(135deg, #f1f3f5 0%, rgba(255,255,255,0) 30%)';
      default:
        return 'linear-gradient(135deg, #e9ecef 0%, rgba(255,255,255,0) 30%)';
    }
  })();

  return (
    <BaseDrawer
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      onOverlayClick={handleClose}
      background={background}
      modal={true}
      direction="right"
      ignoreClickOutsideClasses={[
        'date-picker-element',
        'drawer-inner-content',
        'drawer-content'
      ]}
    >
      <DetailsContainer $status={invoiceStatus}>
        <TitleSection>
          <CloseButton onClick={handleClose}><Close12 /></CloseButton>
          <AmountDueLabel>Amount Due</AmountDueLabel>
          <AmountDueValue>${formatAmount(amount_due || 0)}</AmountDueValue>

          {!showCustomPayment ? (
            <ButtonGroup>
              <Button buttonType="primary" onClick={handleMarkAsPaid}>
                <Checkmark12 /> Mark as Paid
              </Button>
              <Button onClick={() => setShowCustomPayment(true)}>
                Custom Payment
              </Button>
            </ButtonGroup>
          ) : (
            <PaymentForm>
              <InputField fullWidth hasError={!!errors.amount}>
                <label htmlFor="amount">Amount</label>
                <input
                  id="amount"
                  type="number"
                  value={newPayment.amount}
                  onChange={(e) =>
                    setNewPayment({
                      ...newPayment,
                      amount: parseFloat(e.target.value) || 0,
                    })
                  }
                  placeholder="Amount"
                  step="0.01"
                  min="0"
                />
                {errors.amount && <span className="error-message">{errors.amount}</span>}
              </InputField>
              <InputField fullWidth hasError={!!errors.payment_date}>
                <label htmlFor="paymentDate">Payment Date</label>
                <DatePicker
                  id="paymentDate"
                  selectedDate={newPayment.payment_date ? new Date(newPayment.payment_date) : null}
                  onChange={handleDateChange}
                  label="Payment Date"
                  variant="input"
                  placeholder="Select date"
                  placement="left-start"
                  onOpenChange={(isOpen) => {
                    setIsAnyPopoverOpen(isOpen);
                  }}
                />
                {errors.payment_date && <span className="error-message">{errors.payment_date}</span>}
              </InputField>
              <InputField fullWidth hasError={!!errors.notes}>
                <label htmlFor="notes">Notes</label>
                <input
                  id="notes"
                  type="text"
                  value={newPayment.notes ?? ""}
                  onChange={(e) =>
                    setNewPayment({ ...newPayment, notes: e.target.value })
                  }
                  placeholder="Notes"
                />
                {errors.notes && <span className="error-message">{errors.notes}</span>}
              </InputField>
              <ButtonGroup>
                <Button buttonType="primary" onClick={handleAddPayment}>
                  Save Payment
                </Button>
                <Button onClick={() => setShowCustomPayment(false)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </PaymentForm>
          )}
        </TitleSection>

        <PaymentHistorySection>
          <SectionHeader>Payment History</SectionHeader>
          <HistoryContainer>
            {payments.length > 0 ? (
              payments.map((payment, index) => (
                <HistoryItem
                  key={payment.id}
                  variants={historyItemVariants}
                  initial={index === 0 ? "visible" : "hidden"}
                  animate="visible"
                  transition={index === 0 ? {} : {
                    duration: animationDuration,
                    delay: (index - 1) * staggerDuration,
                    ease: [0.25, 0.1, 0.25, 1],
                  }}
                >
                  <HistoryEvent>
                    <HistoryIcon><Money12 /></HistoryIcon>
                    Payment received: ${formatAmount(payment.amount)}
                    <RemoveButton className="remove-button" onClick={() => handleRemovePayment(payment.id)}>
                      <Close12 />
                    </RemoveButton>
                  </HistoryEvent>
                  <HistoryDate $isLast={index === payments.length - 1}>
                    {formatDate(payment.payment_date)}
                  </HistoryDate>
                </HistoryItem>
              ))
            ) : (
              <HistoryItem
                variants={historyItemVariants}
                initial="visible"
                animate="visible"
              >
                <EmptyStateText>No payments recorded yet.</EmptyStateText>
              </HistoryItem>
            )}
          </HistoryContainer>
        </PaymentHistorySection>
      </DetailsContainer>
    </BaseDrawer>
  );
};

export default PaymentsDrawer;
