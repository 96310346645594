import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from '@tanstack/react-router';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { supabase } from '../../../supabaseClient';
import { invoiceTemplates } from '../../../templates/InvoiceTemplates';
import { Client, InvoiceData, Payment, Organization } from '../../../types';
import { calculateInvoiceTotals } from '../../../utils/invoiceCalculations';
import InvoiceItems from '../components/InvoiceItems';
import InvoiceTotals from '../components/InvoiceTotals';
import AnimatedHeader from '../../../shared/components/AnimatedHeader';
import Button from '../../../shared/components/Button';
import { ClientSelector } from '../../clients/components/ClientSelector';
import { differenceInCalendarDays, format, isThisYear } from 'date-fns';
import PublicInvoiceDrawer from '../components/PublicInvoiceDrawer';
import PaymentForm from '../components/PaymentForm';

const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  justify-content: center;
  overflow-y: auto;
  height: 100vh;
  background-color: #F8F7F6;


  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;

  }
`;

const InvoiceDetailsWrapper = styled.div`
  max-width: 320px;
  width: 100%;
  padding: 80px 32px;
  box-sizing: border-box;
  position: sticky;
  top: 0;

  @media (max-width: 1000px) {
    max-width: 100%;
    position: static;
    padding: 32px;
    order: -1;
  }
`;

const InvoiceDetail = styled.div`
  margin-top: 16px;
`;

const DetailLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: rgba(0,0,0,0.5);
  margin-bottom: 4px;
`;

const NoMarginDetailLabel = styled(DetailLabel)`
  margin: 0 0 -12px 0;
`;

const DetailValue = styled.div`
  font-size: 16px;
  line-height: 24px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const TermsAndNotesWrapper = styled.div`
  margin-top: 16px;
`;

const InvoiceTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
`;

const MainContent = styled.div<{ $backgroundColor: string; $bodyTextColor: string; $font: string }>`
  flex: 1;
  width: 100%;
  margin: 0 auto;
  background-color: ${(props) => props.$backgroundColor};
  color: ${(props) => props.$bodyTextColor};
  font-family: ${(props) => props.$font};
  box-shadow: 0 4px 80px rgba(0, 0, 0, 0.1);
`;

const InvoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 32px;
  box-sizing: border-box;
  max-width: 880px;
  width: 100%;

  @media (max-width: 1000px) {
    padding: 16px;
    max-width: 100%;
  }
`;

const InvoiceDetailsContainer = styled.div`
  padding: 64px 64px 80px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 768px) {
    padding: 32px 16px;
  }
`;

const PaymentButton = styled(Button)<{ $isPaid: boolean; $isVisible: boolean }>`
  background-color: ${props => props.$isPaid ? 'transparent' : '#FFFFFF'};
  padding: 8px 16px;
  text-align: center;
  justify-content: center;
  display: ${props => props.$isVisible ? 'flex' : 'none'};
  border: ${props => props.$isPaid ? '2px solid #45a049' : '0.5px solid rgba(0, 0, 0, 0.05)'};
  cursor: ${props => props.$isPaid ? 'default' : 'pointer'};
  font-size: 14px;
  margin-top: 24px;
  width: 100%;
  font-weight: 500;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  transition: box-shadow 0.1s ease, transform 0.1s ease;
  color: ${props => props.$isPaid ? '#45a049' : 'rgba(0,0,0,0.8)'};

  &:hover {
    box-shadow: 0px 2px 1px hsla(0, 0%, 0%, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.05);
    background-color: #FFFFFF;
    transform: ${props => props.$isPaid ? 'none' : 'translateY(-1px)'};
  }

  &:active {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05);
    transform: ${props => props.$isPaid ? 'none' : 'translateY(0) scale(0.99)'};
  }

  &:disabled {
    background-color: ${props => props.$isPaid ? 'transparent' : '#e0e0e0'};
    border: ${props => props.$isPaid ? '1.5px solid #45a049' : '0.5px solid rgba(0, 0, 0, 0.05)'};
    color: ${props => props.$isPaid ? '#45a049' : 'rgba(0,0,0,0.3)'};
    box-shadow: none;

    &:hover {
      transform: none;
    }
  }
`;

const PaymentsList = styled.div`
  padding: 32px 0 80px;
  width: 100%;
  max-width: 880px;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    padding: 32px 16px;
  }

`;

const PaymentItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0,0,0,0.1);
`;

const formatCurrency = (amount: number, currency: string = 'USD') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

const PublicInvoicePage: React.FC = () => {
  const { public_id } = useParams({ from: '/share/$public_id' });
  const [invoice, setInvoice] = useState<InvoiceData | null>(null);
  const [client, setClient] = useState<Client | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const [isPaymentDrawerOpen, setIsPaymentDrawerOpen] = useState(false);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [processing, setProcessing] = useState(false);
  const [isInitializingPayment, setIsInitializingPayment] = useState(false);
  const [initializationFailed, setInitializationFailed] = useState(false);
  const initializationTimeout = useRef<NodeJS.Timeout>();
  const initializationAttempts = useRef(0);
  const MAX_INITIALIZATION_ATTEMPTS = 3;

  const fetchInvoiceAndPayments = useCallback(async () => {
    if (!public_id) {
      setError("No public ID provided");
      return;
    }

    try {
      const { data, error } = await supabase
        .from('invoices')
        .select(`
          *,
          organizations (id, business_name, logo_url, name, address_line1, address_line2, city, state, postal_code, country),
          invoice_items (*),
          payments (*),
          clients (id, full_name, email, address_line1, address_line2, city, state, postal_code, country)
        `)
        .eq('public_id', public_id)
        .single();

      if (error) throw new Error(`Error fetching invoice: ${error.message}`);
      if (!data) throw new Error(`No invoice found with ID: ${public_id}`);

      const { organizations, invoice_items, payments, clients, ...invoiceData } = data;

      setInvoice({
        ...invoiceData,
        business_name: organizations?.business_name,
        logo_url: organizations?.logo_url,
        items: invoice_items || [],
        payments: payments || [],
        show_logo: invoiceData.show_logo,
        payment_terms: invoiceData.payment_terms
      });
      setOrganization(organizations);
      setPayments(payments || []);
      setClient(clients);

    } catch (error) {
      console.error('Unexpected error:', error);
      setError(`An unexpected error occurred: ${error instanceof Error ? error.message : String(error)}`);
      setInvoice(null);
    }
  }, [public_id]);

  useEffect(() => {
    fetchInvoiceAndPayments();
  }, [fetchInvoiceAndPayments]);

  const handlePaymentSuccess = async () => {
    try {
      // Record the payment
      const response = await fetch('/api/record-payment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          invoiceId: invoice?.id,
          amount: invoice?.amount_due,
          paymentDate: new Date().toISOString().split('T')[0],
          notes: 'Payment processed through Stripe',
          userId: null,
          organizationId: invoice?.organization_id,
          paymentMethod: 'card',
          currency: invoice?.currency
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to record payment');
      }

      // Refresh the invoice and payments data
      await fetchInvoiceAndPayments();
      setIsPaymentDrawerOpen(false);
    } catch (err) {
      console.error('Error recording payment:', err);
      setError('Payment was processed but failed to record. Please contact support.');
      throw err; // Re-throw to indicate failure to the payment form
    }
  };

  const initializePayment = useCallback(async () => {
    if (!invoice) {
      console.log('No invoice available');
      return;
    }
    if (!invoice.collect_stripe_payments) {
      console.log('Stripe payments not enabled for this invoice');
      return;
    }
    if (invoice.amount_due <= 0) {
      console.log('No amount due on this invoice');
      return;
    }
    if (clientSecret && stripePromise) {
      console.log('Payment already initialized');
      return;
    }
    if (isInitializingPayment) {
      console.log('Payment initialization already in progress');
      return;
    }
    if (initializationFailed) {
      console.log('Previous initialization attempt failed');
      return;
    }
    if (initializationAttempts.current >= MAX_INITIALIZATION_ATTEMPTS) {
      console.log('Maximum initialization attempts reached');
      setInitializationFailed(true);
      return;
    }

    setIsInitializingPayment(true);
    initializationAttempts.current += 1;
    console.log('Attempting to initialize payment, attempt:', initializationAttempts.current);

    try {
      console.log('Making request to create payment intent for invoice:', invoice.id);
      const response = await fetch('/api/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          invoiceId: invoice.id,
          // Add more debug info
          debug: {
            amount: invoice.amount_due,
            currency: invoice.currency,
            organization_id: invoice.organization_id
          }
        }),
      });

      console.log('Received response:', response.status);
      const responseText = await response.text();
      console.log('Response body:', responseText);

      if (!response.ok) {
        throw new Error(`Failed to create payment intent: ${response.status} ${responseText}`);
      }

      const data = JSON.parse(responseText);
      console.log('Parsed response data:', data);
      
      if (!data.isPaid) {
        console.log('Setting up Stripe with:', {
          clientSecret: data.clientSecret ? 'present' : 'missing',
          stripeAccountId: data.stripeAccountId,
          publishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ? 'present' : 'missing'
        });
        setClientSecret(data.clientSecret);
        setStripePromise(loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, { 
          stripeAccount: data.stripeAccountId 
        }));
      }
    } catch (error) {
      console.error('Detailed error initializing payment:', error);
      setInitializationFailed(true);
      setError(error instanceof Error ? error.message : 'An unexpected error occurred');
    } finally {
      setIsInitializingPayment(false);
    }
  }, [invoice, clientSecret, stripePromise, isInitializingPayment, initializationFailed]);

  // Separate effect for payment initialization that runs after initial render
  useEffect(() => {
    if (invoice && !initializationFailed) {
      // Clear any existing timeout
      if (initializationTimeout.current) {
        clearTimeout(initializationTimeout.current);
      }

      // Only attempt initialization if we haven't exceeded the maximum attempts
      if (initializationAttempts.current < MAX_INITIALIZATION_ATTEMPTS) {
        // Delay payment initialization to not block the main render
        initializationTimeout.current = setTimeout(() => {
          initializePayment();
        }, 1000); // Delay by 1 second to ensure main content is loaded
      }

      return () => {
        if (initializationTimeout.current) {
          clearTimeout(initializationTimeout.current);
        }
      };
    }
  }, [invoice, initializePayment, initializationFailed]);

  const handlePayButtonClick = useCallback(() => {
    if (!invoice) return;
    if (!invoice.collect_stripe_payments) {
      setError('Online payments are not enabled for this invoice.');
      return;
    }

    if (initializationFailed) {
      setError('Payment initialization failed. Please refresh the page and try again.');
      return;
    }

    if (isInitializingPayment) {
      // If still initializing, wait for it to complete
      setError('Please wait while we set up the payment form...');
      return;
    }

    if (!clientSecret || !stripePromise) {
      setError('Failed to initialize payment. Please try again later.');
      return;
    }

    setIsPaymentDrawerOpen(true);
  }, [invoice, clientSecret, stripePromise, isInitializingPayment, initializationFailed]);

  if (error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error}</p>
        <p>Public ID: {public_id || 'Not provided'}</p>
      </div>
    );
  }

  if (!invoice) return '';

  const SelectedTemplate = invoiceTemplates[invoice.invoice_template as keyof typeof invoiceTemplates];
  const totals = calculateInvoiceTotals(invoice);

  const mockOnCreateNewClient = () => {
    console.log('Create new client functionality is not available in public view');
  };

  return (
    <PageWrapper>
      <InvoiceWrapper>
        <MainContent
          $backgroundColor={invoice.background_color || '#ffffff'}
          $bodyTextColor={invoice.body_text_color || '#000000'}
          $font={invoice.font || 'Inter'}
        >
          <AnimatedHeader>
            {SelectedTemplate && (
              <SelectedTemplate
                invoice={{...invoice, logo_url: invoice.logo_url}}
                updateInvoice={() => {}}
                isReadOnly={true}
                clients={client ? [client] : []}
                onCreateNewClient={mockOnCreateNewClient}
                selectedClient={client}
                organization={organization}
                showLogo={invoice.show_logo}
                onOpenOrganizationDrawer={() => {}}
                ClientSelectorComponent={({ clients, selectedClientId, onSelectClient, onCreateNewClient, headerTextColor }) => (
                  <ClientSelector
                    clients={clients}
                    selectedClientId={selectedClientId}
                    onSelectClient={onSelectClient}
                    onCreateNewClient={onCreateNewClient}
                    headerTextColor={headerTextColor}
                    isPreview={true}
                    disabled={true}
                    onEditClient={() => {}}
                  />
                )}
                //onInvoiceDateChange={() => {}} // Add this line
                //onDueDateChange={() => {}} // Add this line
              />
            )}
          </AnimatedHeader>
          <InvoiceDetailsContainer>
            <InvoiceItems
              invoice={{ ...invoice, items: invoice.items || [] }}
              services={[]}
              handleServiceSelect={() => {}}
              handleDescriptionChange={() => {}}
              updateItem={() => {}}
              deleteItem={() => {}}
              reorderItems={() => {}}
              isReadOnly={true}
              onCreateNewService={() => {}}
              backgroundColor={invoice.background_color || '#ffffff'}
              addItem={() => {}}
              isPublic={true}
            />
            <InvoiceTotals
              invoice={{
                ...invoice,
                subtotal: totals.subtotal,
                amount_due: invoice.amount_due,
              }}
              bodyTextColor={invoice.body_text_color || '#000000'}
              backgroundColor={invoice.background_color || '#ffffff'}
              isPublic={true}
            />
          </InvoiceDetailsContainer>
        </MainContent>
        <PaymentsList>
          <h3>Payments</h3>
          {payments.length > 0 ? (
            payments.map((payment) => (
              <PaymentItem key={payment.id}>
                <span>{new Date(payment.payment_date).toLocaleDateString()}</span>
                <span>{formatCurrency(payment.amount, invoice.currency)}</span>
                <span>{payment.payment_method || 'N/A'}</span>
              </PaymentItem>
            ))
          ) : (
            <p>No payments recorded yet.</p>
          )}
        </PaymentsList>
      </InvoiceWrapper>
      <InvoiceDetailsWrapper>
        <InvoiceDetail>
          <InvoiceTitle>
            Invoice #{invoice.invoice_number} from
          </InvoiceTitle>
          <InvoiceTitle>
            {invoice.business_name || 'Not specified'}
          </InvoiceTitle>
        </InvoiceDetail>
        <InvoiceDetail>
          <DetailLabel>Amount Due</DetailLabel>
          <DetailValue>{formatCurrency(invoice.amount_due, invoice.currency)}</DetailValue>
        </InvoiceDetail>
        <InvoiceDetail>
          <DetailLabel>Due Date</DetailLabel>
          <DetailValue>
            {(() => {
              const dueDate = new Date(invoice.due_date);
              const today = new Date();
              const diffDays = differenceInCalendarDays(dueDate, today);
              const formattedDate = format(dueDate, isThisYear(dueDate) ? 'MMM d' : 'MMM d, yyyy');
              
              let statusText = '';
              if (invoice.amount_due > 0) {
                if (diffDays < 0) {
                  statusText = `(late ${Math.abs(diffDays)} day${Math.abs(diffDays) !== 1 ? 's' : ''})`;
                } else if (diffDays === 0) {
                  statusText = '(today)';
                } else if (diffDays === 1) {
                  statusText = '(tomorrow)';
                } else {
                  statusText = `(in ${diffDays} day${diffDays !== 1 ? 's' : ''})`;
                }
              }
              
              return (
                <>
                  {formattedDate}{' '}
                  {statusText && <span style={{ color: 'rgba(0,0,0,0.5)' }}>{statusText}</span>}
                </>
              );
            })()}
          </DetailValue>
        </InvoiceDetail>
        <PaymentButton 
          onClick={handlePayButtonClick}
          disabled={!invoice || invoice.amount_due <= 0}
          $isPaid={!invoice || invoice.amount_due <= 0}
          $isVisible={invoice?.collect_stripe_payments ?? false}
        >
          {!invoice || invoice.amount_due <= 0 ? 'Invoice Paid' : 'Pay Invoice'}
        </PaymentButton>
        <TermsAndNotesWrapper>
          {invoice.payment_terms && (
            <InvoiceDetail>
              <NoMarginDetailLabel>Payment Terms</NoMarginDetailLabel>
              <DetailValue dangerouslySetInnerHTML={{ __html: invoice.payment_terms }} />
            </InvoiceDetail>
          )}
          {invoice.notes && (
            <InvoiceDetail>
              <NoMarginDetailLabel>Notes</NoMarginDetailLabel>
              <DetailValue dangerouslySetInnerHTML={{ __html: invoice.notes }} />
            </InvoiceDetail>
          )}
        </TermsAndNotesWrapper>
      </InvoiceDetailsWrapper>
      <PublicInvoiceDrawer
        isOpen={isPaymentDrawerOpen}
        setIsOpen={setIsPaymentDrawerOpen}
        title="Pay Invoice"
        amount={invoice.amount_due}
        currency={invoice.currency}
        isPaid={invoice.amount_due <= 0}
        isProcessing={processing}
        error={error}
        onSubmit={async (e) => {
          e.preventDefault();
          return Promise.resolve();
        }}
      >
        {clientSecret && stripePromise ? (
          <Elements 
            stripe={stripePromise} 
            options={{ 
              clientSecret,
              appearance: { theme: 'stripe' },
            }}
          >
            <PaymentForm
              onPaymentSuccess={handlePaymentSuccess}
              onError={setError}
              onProcessingChange={setProcessing}
              onPaymentElementReady={() => {}}
            />
          </Elements>
        ) : (
          <div>Loading payment details...</div>
        )}
      </PublicInvoiceDrawer>
    </PageWrapper>
  );
};

export default PublicInvoicePage;
