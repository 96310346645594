import React from 'react';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import TaskDrawer from '../components/TaskDrawer';
import { useClients } from '../../../hooks/';
import { useProjects } from '../../../hooks/';
import { useOrganization, useOrganizationUsers, useUser, useTaskStatuses, useTaskMutations, usePageContext, useTasks, useMediaQuery } from '../../../hooks';
import { Task, Filter, FilterOption, TaskFilterId } from '../../../types';
import { useQueryClient } from '@tanstack/react-query';
import TaskTable from '../components/TaskTable';
import { ColumnDef } from '@tanstack/react-table';
import { Tasks32, Client12, Calendar12, Status12 } from '../../../shared/components/Icon';
import Tabs from '../../../shared/components/Tabs';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import { createPortal } from 'react-dom';
import FilterDrawer from '../../../components/FilterDrawer';
import FilterBox from '../../../components/FilterBox';
import FilterPicker from '../../../components/FilterPicker';
import MultipleEntityPicker from '../../../components/MultipleEntityPicker';
import DateRangePicker from '../../../components/DateRangePicker';
import { isWithinInterval, startOfDay, endOfDay } from 'date-fns';
import { getDateRange } from '../../../utils/dateUtils';
import { useState, useCallback, useMemo, useRef, useEffect } from 'react';


const TasksPageWrapper = styled.div`
  height: calc(100vh - 120px);
  overflow-y: auto;
`;

const PageContent = styled.div`
  height: 100%;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
`;

const FilterBoxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const TasksPage: React.FC = React.memo(() => {
  const { setPageHeaderProps } = usePageContext();
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();
  const { data: tasks, isLoading: tasksLoading, error: tasksError } = useTasks();
  const { data: clients, isLoading: clientsLoading } = useClients();
  const { data: projects, isLoading: projectsLoading } = useProjects();
  const { data: organizationUsers } = useOrganizationUsers();
  const { data: user } = useUser();
  const { taskStatuses, isLoading: statusesLoading, getDefaultStatus } = useTaskStatuses();
  const { updateTaskMutation, createTaskMutation, deleteTaskMutation } = useTaskMutations();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  const isLoading = tasksLoading || clientsLoading || projectsLoading || statusesLoading;

  const containerRef = useRef<HTMLDivElement>(null);

  const sortTasks = useCallback((tasksToSort: Task[]) => {
    return [...tasksToSort].sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  }, []);

  const [isCreatingTask, setIsCreatingTask] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState('all');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState<string | null>(null);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null,
  });
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);

  const updateTask = useCallback(
    (updatedTask: Partial<Task> | { updates: Partial<Task>[]; type: 'batch' }) => {
      if ('type' in updatedTask && updatedTask.type === 'batch') {
        // Handle batch updates
        const updates = updatedTask.updates;
        
        // Update query cache in one go
        queryClient.setQueryData(['tasks', organizationId], (old: Task[] | undefined) => {
          if (!old) return old;
          const newTasks = [...old];
          updates.forEach(update => {
            const index = newTasks.findIndex(task => task.id === update.id);
            if (index !== -1) {
              newTasks[index] = { ...newTasks[index], ...update };
            }
          });
          return newTasks;
        });

        // Send a single API request for all updates
        updateTaskMutation.mutate(
          { type: 'batch', updates },
          {
            onError: (error) => {
              console.error('Failed to update tasks:', error);
              queryClient.invalidateQueries({ queryKey: ['tasks', organizationId] });
            },
          }
        );
      } else {
        // Handle single task update
        const singleUpdate = updatedTask as Partial<Task>;
        if (!singleUpdate.id) return;

        // Update query cache
        queryClient.setQueryData(['tasks', organizationId], (old: Task[] | undefined) => {
          if (!old) return old;
          return old.map(task =>
            task.id === singleUpdate.id ? { ...task, ...singleUpdate } : task
          );
        });

        updateTaskMutation.mutate(singleUpdate, {
          onError: (error) => {
            console.error('Failed to update task:', error);
            queryClient.invalidateQueries({ queryKey: ['tasks', organizationId] });
          },
        });
      }
    },
    [updateTaskMutation, queryClient, organizationId]
  );

  const updateLocalTask = useCallback((updatedTask: Partial<Task>) => {
    if (!organizationId) return;
    
    queryClient.setQueryData(['tasks', organizationId], (old: Task[] | undefined) => {
      if (!old) return old;
      return old.map(task =>
        task.id === updatedTask.id ? { ...task, ...updatedTask } : task
      );
    });
  }, [queryClient, organizationId]);

  const handleCreateTask = useCallback(() => {
    if (!organizationId || !user) {
      console.error('Organization or user not found');
      return;
    }

    if (!taskStatuses || taskStatuses.length === 0) {
      console.log('Waiting for task statuses to load...');
      return;
    }

    setIsCreatingTask(true);

    const defaultStatus = getDefaultStatus();
    
    if (!defaultStatus) {
      console.error('No default status found');
      setIsCreatingTask(false);
      return;
    }

    const columnTasks = tasks
      ?.filter(t => t.status_id === defaultStatus.id)
      .sort((a, b) => a.position - b.position) || [];
    
    const position = columnTasks.length > 0 
      ? columnTasks[0].position - 1024
      : 0;

    const newTask = {
      description: '',
      is_completed: false,
      due_date: null,
      notes: '',
      client_id: null,
      project_id: null,
      user_id: user.id,
      organization_id: organizationId,
      status_id: defaultStatus.id,
      position: position,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };
    
    createTaskMutation.mutate(newTask, {
      onSuccess: (createdTask) => {
        queryClient.setQueryData(['tasks', organizationId], (old: Task[] | undefined) => 
          old ? [createdTask, ...old] : [createdTask]
        );
        setSelectedTaskId(createdTask.id);
        setIsDrawerOpen(true);
        setIsCreatingTask(false);
      },
      onError: (error) => {
        console.error('Error creating task:', error);
        setIsCreatingTask(false);
      }
    });
  }, [
    createTaskMutation,
    organizationId,
    user,
    taskStatuses,
    getDefaultStatus,
    tasks,
    queryClient
  ]);

  const handleDeleteTask = useCallback((taskId: string) => {
    setTaskToDelete(taskId);
    setIsDeleteModalOpen(true);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    if (!taskToDelete || !organizationId) return;
    
    deleteTaskMutation.mutate(taskToDelete, {
      onSuccess: () => {
        queryClient.setQueryData(['tasks', organizationId], (old: Task[] | undefined) => 
          old ? old.filter(task => task.id !== taskToDelete) : []
        );
        setIsDrawerOpen(false);
        setSelectedTaskId(null);
        setIsDeleteModalOpen(false);
        setTaskToDelete(null);
      },
      onError: (error) => {
        console.error('Failed to delete task:', error);
        alert('Failed to delete task. Please try again.');
      },
    });
  }, [deleteTaskMutation, taskToDelete, queryClient, organizationId]);

  const handleTaskClick = useCallback((task: Task) => {
    setSelectedTaskId(task.id);
    setIsDrawerOpen(true);
  }, []);

  const columns = useMemo<ColumnDef<Task>[]>(() => [
    {
      accessorKey: 'description',
      header: 'Description',
      enableSorting: false,
    },
  ], []);

  const handleTasksUpdate = useCallback((updatedTasks: Task[]) => {
    if (!organizationId) return;
    queryClient.setQueryData(['tasks', organizationId], updatedTasks);
  }, [queryClient, organizationId]);

  const filteredData = useMemo(() => {
    if (!tasks) return [];
    
    let filtered = sortTasks(tasks);

    // Apply filters
    filters.forEach((filter: Filter) => {
      switch (filter.id as TaskFilterId) {
        case 'status': {
          const statusFilter = filter.value as string[];
          if (statusFilter.length > 0) {
            filtered = filtered.filter(task => 
              statusFilter.includes(task.status_id)
            );
          }
          break;
        }
        case 'client': {
          const clientFilter = filter.value as string[];
          if (clientFilter.length > 0) {
            filtered = filtered.filter(task => 
              task.client_id && clientFilter.includes(task.client_id)
            );
          }
          break;
        }
        case 'project': {
          const projectFilter = filter.value as string[];
          if (projectFilter.length > 0) {
            filtered = filtered.filter(task => 
              task.project_id && projectFilter.includes(task.project_id)
            );
          }
          break;
        }
        case 'assignee': {
          const assigneeFilter = filter.value as string[];
          if (assigneeFilter.length > 0) {
            filtered = filtered.filter(task => 
              task.user_id && assigneeFilter.includes(task.user_id)
            );
          }
          break;
        }
        case 'date': {
          const dateFilter = filter.value as { startDate: Date | null; endDate: Date | null } | string;
          if (typeof dateFilter === 'object' && 'startDate' in dateFilter && 'endDate' in dateFilter) {
            const { startDate, endDate } = dateFilter;
            if (startDate && endDate) {
              filtered = filtered.filter(task => {
                if (!task.due_date) return false;
                const taskDate = new Date(task.due_date);
                return isWithinInterval(taskDate, { 
                  start: startOfDay(startDate), 
                  end: endOfDay(endDate) 
                });
              });
            }
          } else if (typeof dateFilter === 'string') {
            const { start, end } = getDateRange(dateFilter);
            filtered = filtered.filter(task => {
              if (!task.due_date) return false;
              const taskDate = new Date(task.due_date);
              return isWithinInterval(taskDate, { start, end });
            });
          }
          break;
        }
      }
    });

    // Filter by status (activeTab)
    if (activeTab !== 'all' && taskStatuses) {
      const status = taskStatuses.find(s => s.id === activeTab);
      if (status) {
        filtered = filtered.filter(task => task.status_id === status.id);
      }
    }

    return filtered;
  }, [tasks, filters, activeTab, taskStatuses, sortTasks]);

  const filterOptions = useMemo<FilterOption[]>(() => [
    { id: 'status' as TaskFilterId, label: 'Status', type: 'multipleEntity' },
    { id: 'client' as TaskFilterId, label: 'Client', type: 'multipleEntity' },
    { id: 'project' as TaskFilterId, label: 'Project', type: 'multipleEntity' },
    { id: 'assignee' as TaskFilterId, label: 'Assignee', type: 'multipleEntity' },
    { 
      id: 'date' as TaskFilterId, 
      label: 'Due Date', 
      type: 'dateRange',
      options: [
        'Last Week',
        'Last 30 Days',
        'This Month',
        'Last Month',
        'Last 6 Months',
        'Last Year'
      ]
    },
  ], []);

  const getFilterOptions = useCallback((filterId: string) => {
    const filter = filterOptions.find(f => f.id === filterId);
    return filter?.options;
  }, [filterOptions]);

  const handleAddFilter = useCallback((filterId: TaskFilterId) => {
    if (!filters.some(filter => filter.id === filterId)) {
      const filterOption = filterOptions.find(option => option.id === filterId);
      if (filterOption) {
        const newFilter: Filter = {
          id: filterId,
          label: filterOption.label,
          value: filterId === 'client' || filterId === 'project' || filterId === 'assignee' || filterId === 'status' ? [] : 
                 filterId === 'date' ? { startDate: null, endDate: null } : ''
        };
        setFilters(prevFilters => [...prevFilters, newFilter]);
      }
    }
  }, [filters, filterOptions]);

  const handleRemoveFilter = useCallback((filterId: TaskFilterId) => {
    setFilters(prevFilters => prevFilters.filter(filter => filter.id !== filterId));
  }, []);

  const handleFilterChange = useCallback((filterId: TaskFilterId, value: Filter['value']) => {
    setFilters(prevFilters => 
      prevFilters.map(filter => 
        filter.id === filterId ? { ...filter, value } : filter
      )
    );
  }, []);

  const handleApplyFilters = useCallback(() => {
    setIsFilterDrawerOpen(false);
  }, []);

  const clientEntities = useMemo(() => {
    return clients?.map(client => ({
      id: client.id,
      name: client.full_name
    })) || [];
  }, [clients]);

  const projectEntities = useMemo(() => {
    return (projects || []).reduce<{ id: string; name: string }[]>((acc, project) => {
      if (project.id) {
        acc.push({
          id: project.id,
          name: project.name || 'Untitled Project'
        });
      }
      return acc;
    }, []);
  }, [projects]);

  const userEntities = useMemo(() => {
    return organizationUsers?.map(user => ({
      id: user.id,
      name: user.full_name || user.email
    })) || [];
  }, [organizationUsers]);

  const statusEntities = useMemo(() => {
    return taskStatuses?.map(status => ({
      id: status.id,
      name: status.name
    })) || [];
  }, [taskStatuses]);

  const emptyState = useMemo(() => ({
    icon: <Tasks32 />,
    message: "No tasks found",
    subMessage: "Tasks you create will appear here.",
    action: {
      label: "Create a task",
      onClick: handleCreateTask
    }
  }), [handleCreateTask]);

  useEffect(() => {
    setPageHeaderProps({
      title: "Tasks",
      showBeta: true,
      right: (
        <Button 
          buttonType="primary" 
          onClick={handleCreateTask} 
          disabled={isCreatingTask || !taskStatuses || taskStatuses.length === 0}
        >
          New Task
        </Button>
      ),
    });
  }, [setPageHeaderProps, isCreatingTask, taskStatuses]);

  useEffect(() => {
    const handleTasksCacheUpdate = (event: CustomEvent<{ tasks: Task[], projectId?: string }>) => {
      // Only update if this is not a project-specific update or if we're showing all tasks
      if (!event.detail.projectId) {
        queryClient.setQueryData(['tasks', organizationId], sortTasks(event.detail.tasks));
      }
    };

    window.addEventListener('TASKS_CACHE_UPDATED', handleTasksCacheUpdate as EventListener);

    return () => {
      window.removeEventListener('TASKS_CACHE_UPDATED', handleTasksCacheUpdate as EventListener);
    };
  }, [sortTasks, queryClient, organizationId]);

  const tabCounts = useMemo(() => {
    if (!tasks) return {};
    return tasks.reduce((acc, task) => {
      const status = task.status_id;
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);
  }, [tasks]);

  const tabs = useMemo(() => {
    if (!taskStatuses) return [{ id: 'all', label: 'All' }];

    return [
      { id: 'all', label: 'All' },
      ...taskStatuses.map(status => ({
        id: status.id,
        label: status.name,
        count: tabCounts[status.id] || 0
      }))
    ];
  }, [taskStatuses, tabCounts]);

  const handleCloseTaskDrawer = useCallback(() => {
    if (!isAnyPopoverOpen) {
      setIsDrawerOpen(false);
      setSelectedTaskId(null);
    }
  }, [isAnyPopoverOpen]);

  if (!organizationId) {
    return null;
  }

  if (clientsLoading || projectsLoading || statusesLoading) {
    return null;
  }

  return (
    <TasksPageWrapper ref={containerRef}>
      <PageContent>
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={setActiveTab}
          actionButtons={
            <FilterContainer>
              <FilterBoxesWrapper>
                {filters.map(filter => (
                  <FilterBox
                    key={filter.id}
                    filter={filter}
                    onRemove={() => handleRemoveFilter(filter.id as TaskFilterId)}
                    onChange={(value) => handleFilterChange(filter.id as TaskFilterId, value)}
                    options={getFilterOptions(filter.id)}
                    entities={
                      filter.id === 'client' 
                        ? clientEntities 
                        : filter.id === 'project'
                        ? projectEntities
                        : filter.id === 'assignee'
                        ? userEntities
                        : filter.id === 'status'
                        ? statusEntities
                        : undefined
                    }
                  />
                ))}
              </FilterBoxesWrapper>
              <FilterPicker
                options={filterOptions}
                onAddFilter={(filterId) => handleAddFilter(filterId as TaskFilterId)}
                activeFilters={filters.map(filter => filter.id)}
              />
            </FilterContainer>
          }
        />
        {tasksError ? (
          <div>Error loading tasks. Please try again later.</div>
        ) : (
          <TaskTable
            tasks={filteredData}
            columns={columns}
            isLoading={isLoading}
            onRowClick={handleTaskClick}
            onImmediateUpdate={updateTask}
            getRowKey={(task) => task.id}
            clients={clients || []}
            projects={projects?.map(project => ({
              ...project,
              name: project.name || 'Untitled Project'
            })) || []}
            organizationUsers={organizationUsers || []}
            taskStatuses={taskStatuses || []}
            selectedTaskId={selectedTaskId}
            onTasksUpdate={handleTasksUpdate}
            containerRef={containerRef}
            emptyState={emptyState}
            onDeleteTask={handleDeleteTask}
          />
        )}
      </PageContent>
      <TaskDrawer
        isOpen={isDrawerOpen}
        setIsOpen={handleCloseTaskDrawer}
        selectedTask={(tasks || []).find(t => t.id === selectedTaskId) || null}
        updateTask={updateTask}
        updateLocalTask={updateLocalTask}
        clients={clients || []}
        projects={projects || []}
        organizationUsers={organizationUsers || []}
        taskStatuses={taskStatuses || []}
        onDeleteTask={handleDeleteTask}
        autoFocus={true}
        onPopoverOpenChange={setIsAnyPopoverOpen}
        modal={false}
        ignoreClickOutsideClasses={[
          'task-row',
          'date-picker-element',
          'entity-picker-popover',
          'drawer-inner-content',
          'drawer-content',
          'react-datepicker',
          'react-datepicker__portal',
          'floating-ui-portal'
        ]}
      />
      {createPortal(
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          title="Delete Task"
          message="Are you sure you want to delete this task? This action cannot be undone."
          confirmLabel="Delete"
          onConfirm={handleConfirmDelete}
          onCancel={() => {
            setIsDeleteModalOpen(false);
            setTaskToDelete(null);
          }}
          isDestructive
        />,
        document.body
      )}
      {isSmallScreen && (
        <FilterDrawer
          isOpen={isFilterDrawerOpen}
          setIsOpen={setIsFilterDrawerOpen}
          onApply={handleApplyFilters}
        >
          <MultipleEntityPicker
            selectedIds={filters.find(filter => filter.id === 'client')?.value as string[] || []}
            onChange={(value) => handleFilterChange('client', value)}
            entities={clientEntities}
            label="Clients"
            icon={<Client12 />}
            placement="bottom-start"
          />
          <DateRangePicker
            selectedRange={dateRange}
            onChange={(newRange) => {
              setDateRange(newRange);
              handleFilterChange('date', newRange);
            }}
            label="Due Date Range"
            id="task-date-range"
            variant="preview"
            icon={<Calendar12 />}
          />
          <MultipleEntityPicker
            selectedIds={filters.find(filter => filter.id === 'status')?.value as string[] || []}
            onChange={(value) => handleFilterChange('status', value)}
            entities={statusEntities}
            label="Status"
            icon={<Status12 />}
            placement="bottom-start"
          />
        </FilterDrawer>
      )}
    </TasksPageWrapper>
  );
});

export default TasksPage;
