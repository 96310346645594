import React, { useCallback } from 'react';
import { InvoiceData, EmailTracking } from '../../../types';
import { Close12 } from '../../../shared/components/Icon';
import Button from '../../../shared/components/Button';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';
import { supabase } from '../../../supabaseClient';
import BaseDrawer from '../../../shared/components/BaseDrawer';

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface InvoiceDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  onOverlayClick: (event: React.MouseEvent) => void;
  selectedInvoice: InvoiceData | null;
  modal?: boolean;
  ignoreClickOutsideClasses?: string[];
  onPopoverOpenChange?: (isOpen: boolean) => void;
}

const getStatusBackground = (status: string) => {
  switch (status) {
    case 'unpaid':
      return 'linear-gradient(135deg, #fff4e5 0%, rgba(255,255,255,0) 30%)';
    case 'paid':
      return 'linear-gradient(135deg, #e6f4ea 0%, rgba(255,255,255,0) 30%)';
    case 'overdue':
      return 'linear-gradient(135deg, #fde7e9 0%, rgba(255,255,255,0) 30%)';
    case 'draft':
      return 'linear-gradient(135deg, #f1f3f5 0%, rgba(255,255,255,0) 30%)';
    default:
      return 'linear-gradient(135deg, #e9ecef 0%, rgba(255,255,255,0) 30%)';
  }
};

const InvoiceDrawer: React.FC<InvoiceDrawerProps> = ({
  isOpen,
  setIsOpen,
  children,
  onOverlayClick,
  selectedInvoice,
  modal = false,
  ignoreClickOutsideClasses = [],
  onPopoverOpenChange,
}) => {
  const queryClient = useQueryClient();

  const getInvoiceStatus = useCallback((invoice: InvoiceData) => {
    if (invoice.status === 'paid') return 'paid';
    if (invoice.status === 'draft') return 'draft';
    
    const dueDate = new Date(invoice.due_date);
    const today = new Date();
    
    dueDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    
    if (dueDate.getTime() < today.getTime()) {
      return 'overdue';
    }
    
    return 'unpaid';
  }, []);

  const invoiceStatus = selectedInvoice ? getInvoiceStatus(selectedInvoice) : 'default';
  const background = getStatusBackground(invoiceStatus);

  const prefetchInvoice = useCallback(() => {
    if (selectedInvoice) {
      queryClient.prefetchQuery({
        queryKey: ['invoice', selectedInvoice.id],
        queryFn: async () => {
          try {
            const [
              invoiceResponse,
              itemsResponse,
              paymentsResponse
            ] = await Promise.all([
              supabase
                .from('invoices')
                .select('*')
                .eq('id', selectedInvoice.id)
                .single(),
              supabase
                .from('invoice_items')
                .select('*')
                .eq('invoice_id', selectedInvoice.id)
                .order('order'),
              supabase
                .from('payments')
                .select('*')
                .eq('invoice_id', selectedInvoice.id)
            ]);

            const { data: invoiceData, error: invoiceError } = invoiceResponse;
            const { data: itemsData, error: itemsError } = itemsResponse;
            const { data: paymentsData, error: paymentsError } = paymentsResponse;

            if (invoiceError) throw invoiceError;
            if (itemsError) throw itemsError;
            if (paymentsError) throw paymentsError;

            let emailTrackingData: EmailTracking[] = [];
            try {
              const { data: trackingData, error: trackingError } = await supabase
                .from('email_tracking')
                .select(`
                  id,
                  tracking_id,
                  invoice_id,
                  recipient_email,
                  sent_at,
                  opened,
                  opened_at,
                  open_count,
                  open_history
                `)
                .eq('invoice_id', selectedInvoice.id)
                .order('sent_at', { ascending: false });

              if (!trackingError && trackingData) {
                emailTrackingData = trackingData as EmailTracking[];
              }
            } catch (error) {
              console.warn('Error fetching email tracking data:', error);
            }

            return {
              ...invoiceData,
              items: itemsData || [],
              payments: paymentsData || [],
              email_tracking: emailTrackingData,
              logo_url: selectedInvoice.logo_url,
            };
          } catch (error) {
            console.error('Error prefetching invoice data:', error);
            throw error;
          }
        },
        staleTime: 30000,
      });
    }
  }, [queryClient, selectedInvoice]);

  return (
    <BaseDrawer
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      onOverlayClick={onOverlayClick}
      background={background}
      modal={modal}
      ignoreClickOutsideClasses={[
        'invoice-row',
        'payment-row',
        'date-picker-element',
        'entity-picker-popover',
        'drawer-inner-content',
        'drawer-content',
        ...ignoreClickOutsideClasses
      ]}
    >
      <DrawerHeader>
        <CloseButton onClick={() => setIsOpen(false)}><Close12 /></CloseButton>
      </DrawerHeader>
      {React.Children.map(children, child =>
        React.isValidElement(child)
          ? React.cloneElement(child as React.ReactElement<{
              autoFocus: boolean;
              prefetchInvoice: () => void;
              onPopoverOpenChange?: (isOpen: boolean) => void;
            }>, {
              autoFocus: isOpen,
              prefetchInvoice,
              onPopoverOpenChange
            })
          : child
      )}
    </BaseDrawer>
  );
};

export default InvoiceDrawer;
