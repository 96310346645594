import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import styled from "styled-components";
import { supabase } from "../../../supabaseClient";
import { TimeEntry, GroupedTimeEntry, TimeEntryLayout, TimeTrackerStyle, TIME_TRACKER_TO_ENTRY_LAYOUT } from "../../../types";
import Button from "../../../shared/components/Button";
import { useClients } from "../../clients/hooks/useClients";
import { useProjects } from "../../projects/hooks/useProjects";
import { useServices } from "../../../hooks";
import { debounce } from 'lodash';
import EntityPicker from "../../../shared/components/EntityPicker";
import BaseDrawer from '../../../shared/components/BaseDrawer';
import { Close12, Client12, Project12, Calendar12, Clock12, ArrowRightLine12, Play12, Pause12, Stop12, More12, Service12 } from '../../../shared/components/Icon';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Switch from '../../../shared/components/Switch';
import DatePicker from "../../../components/DatePicker";
import { useTimeTracking } from '../hooks/useTimeTracking';
import { DefaultLayout, EveningLayout, ModernLayout, SunriseLayout, RetroLayout } from './TimeEntryLayouts';
import { timeEntrySchema } from '../../../schemas/timeEntry';
import { z } from 'zod';

// Add this new type for the More menu items
type MoreMenuItem = {
  id: string;
  name: string;
  action: () => void;
};

const formatDuration = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return {
    hours: hours.toString().padStart(2, '0'),
    minutes: minutes.toString().padStart(2, '0'),
    seconds: remainingSeconds.toString().padStart(2, '0'),
  };
};

const parseDuration = (
  { hours, minutes, seconds }: { hours: string; minutes: string; seconds: string }
) => {
  return (
    (parseInt(hours || '0', 10) * 3600) +
    (parseInt(minutes || '0', 10) * 60) +
    parseInt(seconds || '0', 10)
  );
};

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  position: relative;
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditorWrapper = styled.div`
  .ProseMirror {
    min-height: 100px;
    outline: none;
    padding: 24px 40px 80px;
    border-top: 1px solid rgba(0,0,0,0.05);
    font-size: 16px;
    line-height: 24px;

    p.is-editor-empty:first-child::before {
      color: rgba(0, 0, 0, 0.5);
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
  position: absolute;
  right: 32px;
  top: 24px;
  padding: 4px 8px;
`;

const TimeInputWrapper = styled.div<{ hasError?: boolean }>`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;

  input {
    border: ${props => props.hasError ? '1px solid #ff4d4f' : '0'};
    &:focus {
      box-shadow: 0 0 0 1px ${props => props.hasError ? '#ff4d4f' : 'rgba(0,0,0,0.5)'};
    }
  }

  .error-message {
    color: #ff4d4f;
    font-size: 12px;
    margin-top: 4px;
    position: absolute;
    bottom: -20px;
    left: 0;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const TimeInput = styled.input`
  padding: 4px 8px 4px 27px;
  font-size: 14px;
  width: 80px;
  border-radius: 8px;
  border: 0;
  background-color: transparent;
  font-feature-settings: "tnum";
  font-weight: 500;
  color: rgba(0,0,0,0.8);
  cursor: pointer;

  &:hover {
    background-color: rgba(0,0,0,0.1);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
  }
  // Add these lines to remove default browser styling
  -webkit-appearance: none;
  -moz-appearance: textfield;
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

const DateTimeContainer = styled.div`
  padding: 24px 32px 20px 32px;
  gap: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  color: rgba(0,0,0,0.8);
  svg path {
    fill: rgba(0,0,0,0.8);
  }
`;

const InputLabel = styled.label`
  font-size: 12px;
  color: rgba(0,0,0,0.5);
  margin-top: 4px;
  font-weight: 500;
  margin: 0 0 4px 8px;
`;

const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeArrowRightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  svg path {
    fill: rgba(0, 0, 0, 0.25);
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 24px 32px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
`;

const ActionButton = styled(Button)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const EntityPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MoreButtonWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 56px;
  z-index: 1;
`;

interface TimeEntryDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  timeEntry?: TimeEntry | null;
  onSave: (timeEntry: TimeEntry) => void;
  onDelete: (timeEntryId: string) => void;
  onCreate: (timeEntryData: Partial<TimeEntry>) => Promise<TimeEntry>; // Add this line
  organizationId?: string;
  defaultProjectId?: string;
  defaultClientId?: string;
  onOverlayClick: (event: React.MouseEvent) => void;
  stopTimer: () => Promise<void>;
  defaultStartTime?: string;
  defaultEndTime?: string;
  updateLocalTimeEntry: (updatedEntry: Partial<GroupedTimeEntry>) => void;
  currentTimeEntryId: string | null;
  pauseTimer: () => void;
  resumeTimer: () => void;
}

const TimeEntryDrawer: React.FC<TimeEntryDrawerProps> = ({
  isOpen,
  setIsOpen,
  timeEntry,
  onDelete,
  organizationId,
  defaultProjectId,
  defaultClientId,
  onOverlayClick,
  stopTimer,
  defaultStartTime,
  defaultEndTime,
  updateLocalTimeEntry,
  currentTimeEntryId,
  pauseTimer,
  resumeTimer,
  onCreate,
}) => {
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const { isRunning, elapsedTime } = useTimeTracking();
  const [pendingTimeEntry, setPendingTimeEntry] = useState<TimeEntry | null>(null);
  const isCreatingTimeEntryRef = useRef(false);

  // Update the formDataRef type to be mutable
  const formDataRef = useRef<Omit<TimeEntry, "id" | "user_id"> | null>(null);

  // Remove unused isInitializedRef
  const [editingField, setEditingField] = useState<'hours' | 'minutes' | 'seconds' | null>(null);

  // Flag to determine if we're editing a running time entry
  const isEditingRunningEntry = timeEntry && isRunning && timeEntry.id === currentTimeEntryId;

  const handleOpenChange = useCallback((open: boolean) => {
    if (!isAnyPopoverOpen) {
      setIsOpen(open);
    }
  }, [isAnyPopoverOpen, setIsOpen]);

  const handleClose = useCallback(() => {
    if (!isAnyPopoverOpen) {
      handleOpenChange(false);
    }
  }, [handleOpenChange, isAnyPopoverOpen]);

  const [formData, setFormData] = useState<Omit<TimeEntry, "id" | "user_id">>({
    organization_id: organizationId || "",
    client_id: defaultClientId || null,
    project_id: defaultProjectId || null,
    service_id: null,
    start_time: new Date().toISOString(),
    end_time: null,
    duration: 0,
    description: "",
    is_billable: true,
  });

  // Initial formData is set here

  const { data: clients } = useClients();
  const { data: projects } = useProjects();
  const { data: services } = useServices();

  const isEditingRef = useRef(false);

  const [latestIsBillable, setLatestIsBillable] = useState(true);

  const updateTimeEntryMutation = useMutation({
    mutationFn: async (updatedTimeEntry: Partial<TimeEntry>) => {
      if (!updatedTimeEntry.id) throw new Error("No time entry ID provided");
      // Updating time entry in the database
      const { data, error } = await supabase
        .from("time_entries")
        .update(updatedTimeEntry)
        .eq("id", updatedTimeEntry.id)
        .select()
        .single();
      if (error) throw error;
      // Time entry updated successfully
      return data;
    },
    onSuccess: (data) => {
      // Mutation successful, updating local time entry
      updateLocalTimeEntry({ ...data, is_billable: latestIsBillable } as GroupedTimeEntry);
    },
  });

  const debouncedUpdateTimeEntry = useRef(
    debounce(
      (id: string, changes: Partial<TimeEntry>, isEditing: boolean) => {
        updateTimeEntryMutation.mutate({ id, ...changes }, {
          onSettled: () => {
            if (!isEditing) {
              isEditingRef.current = false;
            }
          },
        });
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Add a description...',
      }),
    ],
    content: formData.description || '',
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setFormData(prev => {
        const newData = { ...prev, description: html };
        formDataRef.current = newData;
        return newData;
      });
      
      const currentTimeEntry = timeEntry || pendingTimeEntry;
      if (currentTimeEntry?.id) {
        isEditingRef.current = true;
        debouncedUpdateTimeEntry(currentTimeEntry.id, { description: html }, isEditingRef.current);
        updateLocalTimeEntry({ ...formData, description: html, id: currentTimeEntry.id } as GroupedTimeEntry);
      } else if (!isCreatingTimeEntryRef.current) {
        createInitialTimeEntry();
      }
    },
  });

  useEffect(() => {
    if (isOpen) {
      const initialData = {
        organization_id: organizationId || "",
        client_id: timeEntry?.client_id || defaultClientId || null,
        project_id: timeEntry?.project_id || defaultProjectId || null,
        service_id: timeEntry?.service_id || null,
        start_time: timeEntry?.start_time || defaultStartTime || new Date().toISOString(),
        end_time: isEditingRunningEntry ? null : (timeEntry?.end_time || defaultEndTime || null),
        duration: isEditingRunningEntry ? elapsedTime : (timeEntry?.duration || 0),
        description: timeEntry?.description || "",
        is_billable: timeEntry?.is_billable ?? true,
      };

      setFormData(initialData);
      formDataRef.current = initialData;

      if (editor) {
        editor.commands.setContent(timeEntry?.description || "");
      }
      if (isEditingRunningEntry) {
        setDurationInputs(formatDuration(elapsedTime));
      } else if (timeEntry) {
        const { hours, minutes, seconds } = formatDuration(timeEntry.duration);
        setDurationInputs({ hours, minutes, seconds });
      } else {
        // Reset duration inputs for new entries
        setDurationInputs({ hours: '', minutes: '', seconds: '' });
      }
    } else {
      // Reset all state when drawer is closed
      isCreatingTimeEntryRef.current = false;
      setPendingTimeEntry(null);
      formDataRef.current = null;
      setFormData({
        organization_id: organizationId || "",
        client_id: null,
        project_id: null,
        service_id: null,
        start_time: new Date().toISOString(),
        end_time: null,
        duration: 0,
        description: "",
        is_billable: true,
      });
      setDurationInputs({ hours: '', minutes: '', seconds: '' });
      if (editor) {
        editor.commands.setContent("");
      }
    }
  }, [isOpen, timeEntry, defaultProjectId, defaultClientId, organizationId, editor, defaultStartTime, defaultEndTime, isRunning, elapsedTime, isEditingRunningEntry]);

  // Add createInitialTimeEntry function
  const createInitialTimeEntry = useCallback(async () => {
    if (!organizationId || pendingTimeEntry || timeEntry || isCreatingTimeEntryRef.current || !formDataRef.current) return;

    try {
      isCreatingTimeEntryRef.current = true;

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error("No user found. Please log in and try again.");
        return;
      }

      // Validate before creating
      try {
        timeEntrySchema.parse(formDataRef.current);
        setErrors({});
      } catch (error) {
        if (error instanceof z.ZodError) {
          const newErrors: Record<string, string> = {};
          error.errors.forEach((err) => {
            const field = err.path[0];
            if (typeof field === 'string') {
              newErrors[field] = err.message;
            }
          });
          setErrors(newErrors);
          return;
        }
      }

      const newTimeEntry = await onCreate({
        ...formDataRef.current,
        user_id: user.id,
        organization_id: organizationId,
      });

      setPendingTimeEntry(newTimeEntry);
    } catch (error) {
      console.error("Error creating initial time entry:", error);
    } finally {
      isCreatingTimeEntryRef.current = false;
    }
  }, [organizationId, pendingTimeEntry, timeEntry, onCreate]);

  // Update handleEntityChange to create initial time entry
  const handleEntityChange = useCallback((field: keyof TimeEntry, value: string | null) => {
    // Updating entity field
    const updatedData = { [field]: value } as Partial<Omit<TimeEntry, "id" | "user_id">>;
    setFormData((prev) => {
      const newData = { ...prev, ...updatedData };
      formDataRef.current = newData;
      return newData;
    });

    const currentTimeEntry = timeEntry || pendingTimeEntry;
    if (currentTimeEntry?.id) {
      isEditingRef.current = true;
      debouncedUpdateTimeEntry(currentTimeEntry.id, updatedData, isEditingRef.current);
      updateLocalTimeEntry({ ...formData, ...updatedData, id: currentTimeEntry.id } as GroupedTimeEntry);
    } else if (!isCreatingTimeEntryRef.current) {
      createInitialTimeEntry();
    }
  }, [timeEntry, pendingTimeEntry, formData, debouncedUpdateTimeEntry, updateLocalTimeEntry, createInitialTimeEntry]);

  // Update handleBillableToggle to create initial time entry
  const handleBillableToggle = useCallback((newIsBillable: boolean) => {
    setLatestIsBillable(newIsBillable);
    
    setFormData((prev) => {
      const newData = { ...prev, is_billable: newIsBillable };
      formDataRef.current = newData;
      return newData;
    });

    const currentTimeEntry = timeEntry || pendingTimeEntry;
    if (currentTimeEntry?.id) {
      isEditingRef.current = true;
      debouncedUpdateTimeEntry(currentTimeEntry.id, { is_billable: newIsBillable }, isEditingRef.current);
      updateLocalTimeEntry({ ...formData, is_billable: newIsBillable, id: currentTimeEntry.id } as GroupedTimeEntry);
    } else if (!isCreatingTimeEntryRef.current) {
      createInitialTimeEntry();
    }
  }, [timeEntry, pendingTimeEntry, formData, debouncedUpdateTimeEntry, updateLocalTimeEntry, createInitialTimeEntry]);

  // Update editor's onUpdate to create initial time entry
  useEffect(() => {
    if (!editor) return;

    editor.on('update', ({ editor }) => {
      const html = editor.getHTML();
      setFormData(prev => {
        const newData = { ...prev, description: html };
        formDataRef.current = newData;
        return newData;
      });
      
      const currentTimeEntry = timeEntry || pendingTimeEntry;
      if (currentTimeEntry?.id) {
        isEditingRef.current = true;
        debouncedUpdateTimeEntry(currentTimeEntry.id, { description: html }, isEditingRef.current);
      } else if (!isCreatingTimeEntryRef.current) {
        createInitialTimeEntry();
      }
    });

    return () => {
      editor.off('update');
    };
  }, [editor, timeEntry, pendingTimeEntry, debouncedUpdateTimeEntry, createInitialTimeEntry]);

  const handleDateChange = (field: 'start_time' | 'end_time') => (date: Date | null) => {
    if (date) {
      const currentTime = new Date(formData[field] || new Date());
      const newDateTime = new Date(date);
      newDateTime.setHours(currentTime.getHours(), currentTime.getMinutes());
      
      let updatedData = { ...formData, [field]: newDateTime.toISOString() };

      if (field === 'start_time') {
        const endTime = new Date(newDateTime.getTime() + formData.duration * 1000);
        updatedData = { ...updatedData, end_time: endTime.toISOString() };
      } else if (field === 'end_time') {
        const startTime = new Date(formData.start_time);
        const duration = Math.floor((newDateTime.getTime() - startTime.getTime()) / 1000);
        updatedData = { ...updatedData, duration };
        const { hours, minutes, seconds } = formatDuration(duration);
        setDurationInputs({ hours, minutes, seconds });
      }

      setFormData((prev) => {
        const newData = { ...prev, ...updatedData };
        formDataRef.current = newData;
        return newData;
      });

      const currentTimeEntry = timeEntry || pendingTimeEntry;
      if (currentTimeEntry?.id) {
        isEditingRef.current = true;
        debouncedUpdateTimeEntry(currentTimeEntry.id, updatedData, isEditingRef.current);
        updateLocalTimeEntry({ ...formData, ...updatedData, id: currentTimeEntry.id } as GroupedTimeEntry);
      } else if (!isCreatingTimeEntryRef.current) {
        createInitialTimeEntry();
      }
    }
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const [hours, minutes] = value.split(':').map(Number);
    
    const currentDate = new Date(formData[name as 'start_time' | 'end_time'] || new Date());
    currentDate.setHours(hours, minutes);

    let updatedData = { ...formData, [name]: currentDate.toISOString() };

    if (name === 'start_time') {
      const endTime = new Date(currentDate.getTime() + formData.duration * 1000);
      updatedData = { ...updatedData, end_time: endTime.toISOString() };
    } else if (name === 'end_time') {
      const startTime = new Date(formData.start_time);
      const duration = Math.floor((currentDate.getTime() - startTime.getTime()) / 1000);
      updatedData = { ...updatedData, duration };
      const { hours, minutes, seconds } = formatDuration(duration);
      setDurationInputs({ hours, minutes, seconds });
    }

    setFormData((prev) => {
      const newData = { ...prev, ...updatedData };
      formDataRef.current = newData;
      return newData;
    });

    const currentTimeEntry = timeEntry || pendingTimeEntry;
    if (currentTimeEntry?.id) {
      isEditingRef.current = true;
      debouncedUpdateTimeEntry(currentTimeEntry.id, updatedData, isEditingRef.current);
      updateLocalTimeEntry({ ...formData, ...updatedData, id: currentTimeEntry.id } as GroupedTimeEntry);
    } else if (!isCreatingTimeEntryRef.current) {
      createInitialTimeEntry();
    }
  };

  const formatTime = (dateString: string | null) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  const [durationInputs, setDurationInputs] = useState({ hours: '', minutes: '', seconds: '' });

  // Ensure handleDurationChange is defined only once
  const handleDurationChange = (field: 'hours' | 'minutes' | 'seconds') => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (value !== '' && isNaN(Number(value))) return;

    const newDurationInputs = { ...durationInputs, [field]: value };
    setDurationInputs(newDurationInputs);

    // Calculate the new duration in seconds
    const newDuration = parseDuration({
      hours: newDurationInputs.hours || '0',
      minutes: newDurationInputs.minutes || '0',
      seconds: newDurationInputs.seconds || '0',
    });

    const startTime = new Date(formData.start_time);
    const endTime = new Date(startTime.getTime() + newDuration * 1000);

    const updatedData: Partial<TimeEntry> = {
      ...formData,
      duration: newDuration,
      end_time: endTime.toISOString(),
    };

    // Validate the new data
    try {
      timeEntrySchema.parse(updatedData);
      // Remove duration error if validation passes
      setErrors(prev => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { duration: _, ...rest } = prev;
        return rest;
      });
    } catch (error) {
      if (error instanceof z.ZodError) {
        const durationError = error.errors.find(err => err.path[0] === 'duration');
        if (durationError) {
          setErrors(prev => ({ ...prev, duration: durationError.message }));
        }
      }
    }

    setFormData((prev) => {
      const newData = { ...prev, ...updatedData };
      formDataRef.current = newData;
      return newData;
    });

    const currentTimeEntry = timeEntry || pendingTimeEntry;
    if (currentTimeEntry?.id) {
      isEditingRef.current = true;
      debouncedUpdateTimeEntry(currentTimeEntry.id, updatedData, isEditingRef.current);
      updateLocalTimeEntry({ ...formData, ...updatedData, id: currentTimeEntry.id } as GroupedTimeEntry);
    } else if (!isCreatingTimeEntryRef.current) {
      createInitialTimeEntry();
    }
  };

  const handlePauseResume = () => {
    if (isRunning) {
      pauseTimer();
    } else {
      resumeTimer();
    }
  };

  const handleLogTime = async () => {
    await stopTimer();
    handleClose();
  };

  const isCurrentlyRunning = isRunning && timeEntry?.id === currentTimeEntryId;

  const handleDurationClick = (field: 'hours' | 'minutes' | 'seconds') => {
    if (!isCurrentlyRunning) {
      setEditingField(field);
    }
  };

  const handleDurationBlur = () => {
    setEditingField(null);
  };

  // Update the useEffect for live timer updates:
  useEffect(() => {
    let interval: NodeJS.Timeout;
    
    if (isEditingRunningEntry) {
      interval = setInterval(() => {
        const formattedDuration = formatDuration(elapsedTime);
        setDurationInputs(formattedDuration);

        const newDuration = parseDuration(formattedDuration);
        const startTime = new Date(formData.start_time);
        const endTime = new Date(startTime.getTime() + newDuration * 1000);

        setFormData(prev => ({
          ...prev,
          duration: newDuration,
          end_time: endTime.toISOString(),
        }));
      }, 1000); // Update every second
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isEditingRunningEntry, elapsedTime]);

  // Replace the existing handleDeleteTimeEntry function with this:
  const handleDeleteTimeEntry = useCallback(async () => {
    if (timeEntry?.id) {
      try {
        // First, delete related entries in time_entry_invoice_items
        const { error: timeEntryInvoiceItemsError } = await supabase
          .from('time_entry_invoice_items')
          .delete()
          .eq('time_entry_id', timeEntry.id);

        if (timeEntryInvoiceItemsError) {
          console.error('Error deleting related time_entry_invoice_items:', timeEntryInvoiceItemsError);
          throw timeEntryInvoiceItemsError;
        }

        // Then, delete the time entry
        const { error: deleteError } = await supabase
          .from('time_entries')
          .delete()
          .eq('id', timeEntry.id);

        if (deleteError) {
          console.error('Error deleting time entry:', deleteError);
          throw deleteError;
        }

        onDelete(timeEntry.id);
        setIsOpen(false);
      } catch (error) {
        console.error('Failed to delete time entry:', error);
        alert('Failed to delete time entry. Please try again.');
      }
    }
  }, [timeEntry, onDelete, setIsOpen]);

  // Replace the existing layout state with this:
  const [layout, setLayout] = useState<TimeEntryLayout>(() => {
    // First try to get the layout that matches the time tracker style
    const savedTrackerStyle = localStorage.getItem('timeTrackerStyle') as TimeTrackerStyle;
    if (savedTrackerStyle) {
      return TIME_TRACKER_TO_ENTRY_LAYOUT[savedTrackerStyle];
    }
    // Fall back to saved time entry layout or Modern
    return (localStorage.getItem('timeEntryLayout') as TimeEntryLayout) || 'Modern';
  });

  // Update the layout saving logic
  const handleLayoutChange = useCallback((newLayout: TimeEntryLayout) => {
    setLayout(newLayout);
    localStorage.setItem('timeEntryLayout', newLayout);
    // Find the corresponding time tracker style
    const timeTrackerStyle = Object.entries(TIME_TRACKER_TO_ENTRY_LAYOUT).find(
      ([, entryLayout]) => entryLayout === newLayout // Removed unused '_' parameter
    )?.[0] as TimeTrackerStyle;
    
    if (timeTrackerStyle) {
      localStorage.setItem('timeTrackerStyle', timeTrackerStyle);
      // Dispatch a custom event to notify the popover
      window.dispatchEvent(new CustomEvent('timeEntryLayoutChange', { 
        detail: { timeTrackerStyle } 
      }));
    }
  }, []);

  // Listen for changes from the popover
  useEffect(() => {
    const handlePopoverLayoutChange = (event: CustomEvent<{ timeEntryLayout: TimeEntryLayout }>) => {
      setLayout(event.detail.timeEntryLayout);
      localStorage.setItem('timeEntryLayout', event.detail.timeEntryLayout);
    };

    window.addEventListener('timeTrackerLayoutChange', handlePopoverLayoutChange as EventListener);
    return () => {
      window.removeEventListener('timeTrackerLayoutChange', handlePopoverLayoutChange as EventListener);
    };
  }, []);

  // Create the More menu items
  const moreMenuItems: MoreMenuItem[] = [
    { id: 'layout-default', name: 'Default Layout', action: () => handleLayoutChange('Default') },
    { id: 'layout-evening', name: 'Evening Layout', action: () => handleLayoutChange('Evening') },
    { id: 'layout-modern', name: 'Modern Layout', action: () => handleLayoutChange('Modern') },
    { id: 'layout-sunrise', name: 'Sunrise Layout', action: () => handleLayoutChange('Sunrise') },
    { id: 'layout-retro', name: 'Retro Layout', action: () => handleLayoutChange('Retro') },
    { id: 'delete', name: 'Delete', action: handleDeleteTimeEntry },
  ];

  const renderDurationLayout = () => {
    const layoutProps = {
      durationInputs,
      isCurrentlyRunning,
      editingField,
      handleDurationClick,
      handleDurationChange,
      handleDurationBlur,
    };

    switch (layout) {
      case 'Evening':
        return <EveningLayout {...layoutProps} />;
      case 'Modern':
        return <ModernLayout {...layoutProps} />;
      case 'Sunrise':
        return <SunriseLayout {...layoutProps} />;
      case 'Retro':
        return <RetroLayout {...layoutProps} />;
      default:
        return <DefaultLayout {...layoutProps} />;
    }
  };

  useEffect(() => {
    if (editor && formData.description !== editor.getHTML()) {
      editor.commands.setContent(formData.description || '');
    }
  }, [editor, formData.description]);

  const [errors, setErrors] = useState<Record<string, string>>({});

  return (
    <BaseDrawer
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      onOverlayClick={onOverlayClick}
      modal={false}
      ignoreClickOutsideClasses={[
        'time-entry-item',
        'date-picker-element',
        'entity-picker-popover',
        'drawer-inner-content',
        'drawer-content'
      ]}
    >
      <CloseButton onClick={() => setIsOpen(false)}><Close12 /></CloseButton>
      {timeEntry && (
        <MoreButtonWrapper>
          <EntityPicker
            selectedId={null}
            onChange={(id) => {
              const selectedItem = moreMenuItems.find(item => item.id === id);
              if (selectedItem) {
                selectedItem.action();
              }
            }}
            entities={moreMenuItems}
            label="More"
            icon={<More12 />}
            iconOnly
            placement="bottom-end"
            enableSearch={false}
          />
        </MoreButtonWrapper>
      )}
      {renderDurationLayout()}
      {isCurrentlyRunning ? (
        <ActionButtonsContainer>
          <ActionButton onClick={handlePauseResume}>
            {isRunning ? <><Pause12 /> Pause</> : <><Play12 /> Resume</>}
          </ActionButton>
          <ActionButton onClick={handleLogTime}>
            <Stop12 /> Log Time
          </ActionButton>
        </ActionButtonsContainer>
      ) : (
        <DateTimeContainer>
          <InputField>
            <InputLabel htmlFor="start_time">Start</InputLabel>
            <DateTimeWrapper>
              <DatePicker
                selectedDate={formData.start_time ? new Date(formData.start_time) : null}
                onChange={handleDateChange('start_time')}
                label="Start Date"
                id="start_date"
                placement="left-start"
                icon={<Calendar12 />}
                onOpenChange={(isOpen) => {
                  setIsAnyPopoverOpen(isOpen);
                }}
              />
              <TimeInputWrapper hasError={!!errors.start_time}>
                <IconWrapper>
                  <Clock12 />
                </IconWrapper>
                <TimeInput
                  type="time"
                  id="start_time"
                  name="start_time"
                  value={formatTime(formData.start_time)}
                  onChange={handleTimeChange}
                  required
                />
                {errors.start_time && <span className="error-message">{errors.start_time}</span>}
              </TimeInputWrapper>
            </DateTimeWrapper>
          </InputField>
          <TimeArrowRightWrapper>
            <ArrowRightLine12 />
          </TimeArrowRightWrapper>
          <InputField>
            <InputLabel htmlFor="end_time">End</InputLabel>
            <DateTimeWrapper>
              <DatePicker
                selectedDate={formData.end_time ? new Date(formData.end_time) : null}
                onChange={handleDateChange('end_time')}
                label="End Date"
                id="end_date"
                placement="left-start"
                icon={<Calendar12 />}
                onOpenChange={(isOpen) => {
                  setIsAnyPopoverOpen(isOpen);
                }}
              />
              <TimeInputWrapper hasError={!!errors.end_time}>
                <IconWrapper>
                  <Clock12 />
                </IconWrapper>
                <TimeInput
                  type="time"
                  id="end_time"
                  name="end_time"
                  value={formatTime(formData.end_time)}
                  onChange={handleTimeChange}
                />
                {errors.end_time && <span className="error-message">{errors.end_time}</span>}
              </TimeInputWrapper>
            </DateTimeWrapper>
          </InputField>
        </DateTimeContainer>
      )}
      <InputFieldsContainer>
        <EntityPickerWrapper>
          <EntityPicker
            selectedId={formData.client_id}
            onChange={(id) => handleEntityChange("client_id", id)}
            entities={clients?.map(client => ({ id: client.id, name: client.full_name })) || []}
            label="Client"
            allowUnassigned
            icon={<Client12 />}
            onPopoverOpenChange={setIsAnyPopoverOpen}
          />
        </EntityPickerWrapper>
        <EntityPickerWrapper>
          <EntityPicker
            selectedId={formData.project_id}
            onChange={(id) => handleEntityChange("project_id", id)}
            entities={projects?.map(project => ({ id: project.id, name: project.name || 'Untitled Project' })) || []}
            label="Project"
            allowUnassigned
            icon={<Project12 />}
            onPopoverOpenChange={setIsAnyPopoverOpen}
          />
        </EntityPickerWrapper>
        <EntityPickerWrapper>
          <EntityPicker
            selectedId={formData.service_id}
            onChange={(id) => handleEntityChange("service_id", id)}
            entities={services?.map(service => ({ id: service.id, name: service.name })) || []}  
            label="Service"
            allowUnassigned
            icon={<Service12 />}
            onPopoverOpenChange={setIsAnyPopoverOpen}
          />
        </EntityPickerWrapper>
        <InputField>
          <ToggleWrapper>
            <Switch
              checked={formData.is_billable}
              onChange={handleBillableToggle}
              label={formData.is_billable ? "Billable" : "Non-billable"}
              labelPosition="left"
            />
          </ToggleWrapper>
        </InputField>
      </InputFieldsContainer>
      <EditorWrapper>
        <EditorContent editor={editor} />
      </EditorWrapper>
    </BaseDrawer>
  );
};

export default TimeEntryDrawer;
