import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Client } from '../../../types';
import { useClients } from '../../../hooks';
import { Toggle } from '../../../shared/components/Toggle';
import Button from '../../../shared/components/Button';
import { Close12, Plus12 } from '../../../shared/components/Icon';
import { clientSchema, getCountryOptions, getPostalCodeLabel, countries, CountryCode } from '../../../schemas/client';
import { z } from 'zod';
import BaseDrawer from '../../../shared/components/BaseDrawer';

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const DrawerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: rgba(0,0,0,0.8);
  margin-bottom: 12px;
`

const InputFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const InputField = styled.div<{ fullWidth?: boolean; hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  grid-column: ${props => props.fullWidth ? '1 / -1' : 'auto'};

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  input, select {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: ${props => props.hasError ? '1px solid #ff4d4f' : '0'};
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px ${props => props.hasError ? '#ff4d4f' : 'rgba(0,0,0,0.5)'};
    }
  }

  .error-message {
    color: #ff4d4f;
    font-size: 12px;
    margin-top: 4px;
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0,0,0,0.8);

  label {
    margin: 0;
  }
`;

const AddressGroup = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-top: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const AddressGroupHeader = styled.div`
  grid-column: 1 / -1;
  margin: 12px 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 12px;
  font-weight: 500;
`;

const ButtonFooter = styled.div`
  display: flex;
  position: fixed;
  margin-top: 20px;
  bottom: 8px;
  left: 8px;
  right: 8px;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #F8F7F6;
  border-radius: 0 0 8px 8px;
  gap: 10px;
  box-sizing: border-box;
`;

const CancelButton = styled(Button)`
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const AddAddressButton = styled(Button)`
  margin: 24px 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 8px;
  background-color: transparent;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.6);
  grid-column: 1 / -1;
  padding: 12px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

interface NewClientDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClientAdded: (newClient: Client) => void;
  editingClient?: Client | null;
  organizationId: string;
}

type ClientFormData = Omit<Client, 'id' | 'organization_id'>;

const formatPhoneNumber = (phone: string): string => {
  // Remove all non-digit characters
  const digits = phone.replace(/\D/g, '');
  
  // Handle different formats based on length
  if (digits.length === 10) {
    // Format: (555) 555-5555
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;
  } else if (digits.length === 11 && digits.startsWith('1')) {
    // Format: +1 (555) 555-5555
    return `+1 (${digits.slice(1, 4)}) ${digits.slice(4, 7)}-${digits.slice(7)}`;
  } else if (digits.length === 11) {
    // Format: +X (555) 555-5555
    return `+${digits.slice(0, 1)} (${digits.slice(1, 4)}) ${digits.slice(4, 7)}-${digits.slice(7)}`;
  }
  return digits; // Return just the digits if no format matches
};

// Helper function to convert null to empty string for input values
const nullToEmpty = (value: string | null): string => value ?? '';

const ClientDrawer: React.FC<NewClientDrawerProps> = ({ isOpen, setIsOpen, onClientAdded, editingClient, organizationId }) => {
  const { createClient, updateClient } = useClients();
  const [newClient, setNewClient] = useState<Omit<Client, 'id'>>({
    organization_id: organizationId,
    full_name: '',
    email: '',
    phone: '',
    company: '',
    default_currency: 'USD',
    is_active: true,
    created_at: new Date().toISOString(),
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: null,
    notes: ''
  });

  const [showAddressFields, setShowAddressFields] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isOpen && editingClient) {
      setNewClient(editingClient);
      setShowAddressFields(!!editingClient.address_line1);
    } else if (!isOpen) {
      // Reset to initial state when closing
      setNewClient({
        organization_id: organizationId,
        full_name: '',
        email: '',
        phone: '',
        company: '',
        default_currency: 'USD',
        is_active: true,
        created_at: new Date().toISOString(),
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        postal_code: '',
        country: null,
        notes: ''
      });
      setShowAddressFields(false);
    }
  }, [isOpen, editingClient, organizationId]);

  const handleInputChange = (field: keyof ClientFormData, value: string | boolean) => {
    console.log('Input changed:', { field, value });
    if (field === 'phone' && typeof value === 'string') {
      const formattedPhone = formatPhoneNumber(value);
      console.log('Formatted phone:', formattedPhone);
      setNewClient(prev => ({ ...prev, phone: formattedPhone }));
      return;
    }

    if (field === 'country') {
      const countryValue = value === '' ? null : value as string;
      setNewClient(prev => ({ 
        ...prev, 
        country: countryValue,
        postal_code: null // Reset postal code when country changes
      }));
      return;
    }
    
    // Handle string fields that can be null
    if (typeof value === 'string') {
      const nullableFields: Array<keyof ClientFormData> = [
        'email', 'phone', 'company', 'address_line1', 'address_line2',
        'city', 'state', 'postal_code', 'notes'
      ];
      
      if (nullableFields.includes(field)) {
        setNewClient(prev => ({ 
          ...prev, 
          [field]: value || null 
        }));
        return;
      }
    }

    setNewClient(prev => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    try {
      console.log('Validating form data:', newClient);
      clientSchema.parse(newClient);
      setErrors({});
      return true;
    } catch (error) {
      console.error('Form validation error:', error);
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          const field = err.path[0];
          if (typeof field === 'string') {
            newErrors[field] = err.message;
          }
        });
        setErrors(newErrors);
        console.log('Validation errors:', newErrors);
      }
      return false;
    }
  };

  const handleSaveClient = async () => {
    console.log('Attempting to save client...');
    if (!validateForm()) {
      console.log('Form validation failed');
      return;
    }

    try {
      let data;
      if (editingClient) {
        console.log('Updating existing client:', editingClient.id);
        if (updateClient && updateClient.mutateAsync) {
          data = await updateClient.mutateAsync({ id: editingClient.id, ...newClient });
        } else {
          throw new Error('Update client mutation is not available');
        }
      } else {
        console.log('Creating new client with data:', newClient);
        if (createClient && createClient.mutateAsync) {
          data = await createClient.mutateAsync(newClient);
        } else {
          throw new Error('Create client mutation is not available');
        }
      }
      console.log('Server response:', data);
      if (data && data.id) {
        console.log('Client successfully saved with ID:', data.id);
        onClientAdded(data);
        setIsOpen(false);
      } else {
        console.error('Invalid client data received:', data);
      }
    } catch (error) {
      console.error('Error saving client:', error);
    }
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <BaseDrawer
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      onOverlayClick={handleClose}
      modal={true}
      direction={isMobile ? "bottom" : "right"}
      ignoreClickOutsideClasses={[
        'drawer-inner-content',
        'drawer-content'
      ]}
    >
      <div style={{ padding: '40px 40px 120px 40px' }}>
        <DrawerHeader>
          <DrawerTitle>{editingClient ? 'Edit Client' : 'New Client'}</DrawerTitle>
          <CloseButton onClick={handleClose}><Close12 /></CloseButton>
        </DrawerHeader>
        <InputFieldsContainer>
          <InputField fullWidth>
            <label htmlFor="fullName">Full Name</label>
            <input
              id="fullName"
              type="text"
              value={newClient.full_name}
              onChange={(e) => handleInputChange("full_name", e.target.value)}
              placeholder="Full Name"
              required
            />
            {errors.full_name && <span className="error-message">{errors.full_name}</span>}
          </InputField>
          <InputField>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={nullToEmpty(newClient.email)}
              onChange={(e) => handleInputChange("email", e.target.value)}
              placeholder="Email"
            />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </InputField>
          <InputField>
            <label htmlFor="phone">Phone</label>
            <input
              id="phone"
              type="tel"
              value={nullToEmpty(newClient.phone)}
              onChange={(e) => handleInputChange("phone", e.target.value)}
              placeholder="(555) 555-5555"
            />
            {errors.phone && <span className="error-message">{errors.phone}</span>}
          </InputField>
          <InputField fullWidth>
            <label htmlFor="company">Company</label>
            <input
              id="company"
              type="text"
              value={nullToEmpty(newClient.company)}
              onChange={(e) => handleInputChange("company", e.target.value)}
              placeholder="Company"
            />
            {errors.company && <span className="error-message">{errors.company}</span>}
          </InputField>
          {!showAddressFields && (
            <AddAddressButton onClick={() => setShowAddressFields(true)}>
              <Plus12 />
              Add Address
            </AddAddressButton>
          )}
          {showAddressFields && (
            <AddressGroup>
              <AddressGroupHeader>Address</AddressGroupHeader>
              <InputField fullWidth>
                <label htmlFor="addressLine1">Address Line 1</label>
                <input
                  id="addressLine1"
                  type="text"
                  value={nullToEmpty(newClient.address_line1)}
                  onChange={(e) => handleInputChange("address_line1", e.target.value)}
                  placeholder="Address Line 1"
                />
              </InputField>
              <InputField fullWidth>
                <label htmlFor="addressLine2">Address Line 2</label>
                <input
                  id="addressLine2"
                  type="text"
                  value={nullToEmpty(newClient.address_line2)}
                  onChange={(e) => handleInputChange("address_line2", e.target.value)}
                  placeholder="Address Line 2"
                />
              </InputField>
              <InputField>
                <label htmlFor="city">City</label>
                <input
                  id="city"
                  type="text"
                  value={nullToEmpty(newClient.city)}
                  onChange={(e) => handleInputChange("city", e.target.value)}
                  placeholder="City"
                />
              </InputField>
              <InputField>
                <label htmlFor="state">State/Province</label>
                <input
                  id="state"
                  type="text"
                  value={nullToEmpty(newClient.state)}
                  onChange={(e) => handleInputChange("state", e.target.value)}
                  placeholder="State/Province"
                />
              </InputField>
              <InputField>
                <label htmlFor="country">Country</label>
                <select
                  id="country"
                  value={nullToEmpty(newClient.country)}
                  onChange={(e) => handleInputChange("country", e.target.value)}
                >
                  <option value="">Select a country</option>
                  {getCountryOptions().map((option: { value: string; label: string }) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors.country && <span className="error-message">{errors.country}</span>}
              </InputField>
              <InputField>
                <label htmlFor="postalCode">{getPostalCodeLabel(newClient.country)}</label>
                <input
                  id="postalCode"
                  type="text"
                  value={nullToEmpty(newClient.postal_code)}
                  onChange={(e) => handleInputChange("postal_code", e.target.value)}
                  placeholder={newClient.country ? countries[newClient.country as CountryCode]?.example : "Enter postal code"}
                />
                {errors.postal_code && <span className="error-message">{errors.postal_code}</span>}
              </InputField>
            </AddressGroup>
          )}
          <InputField fullWidth>
            <label htmlFor="defaultCurrency">Default Currency</label>
            <select
              id="defaultCurrency"
              value={newClient.default_currency}
              onChange={(e) => handleInputChange("default_currency", e.target.value)}
            >
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
              <option value="CAD">CAD</option>
              <option value="AUD">AUD</option>
              <option value="JPY">JPY</option>
              <option value="CHF">CHF</option>
              <option value="CNY">CNY</option>
              <option value="INR">INR</option>
              <option value="NZD">NZD</option>
            </select>
            {errors.default_currency && <span className="error-message">{errors.default_currency}</span>}
          </InputField>
          <InputField>
            <label>Status</label>
            <ToggleWrapper>
              <Toggle
                checked={newClient.is_active}
                onChange={(checked) => handleInputChange("is_active", checked)}
              />
              {newClient.is_active ? "Active" : "Inactive"}
            </ToggleWrapper>
          </InputField>
        </InputFieldsContainer>
        <ButtonFooter>
          <Button onClick={(e) => {
            console.log('Create/Update button clicked');
            e.preventDefault();
            handleSaveClient();
          }}>
            {editingClient ? 'Update Client' : 'Create Client'}
          </Button>
          <CancelButton onClick={(e) => {
            console.log('Cancel button clicked');
            e.preventDefault();
            handleClose();
          }}>Cancel</CancelButton>
        </ButtonFooter>
      </div>
    </BaseDrawer>
  );
};

export default ClientDrawer;