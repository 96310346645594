import React, { useState, useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import HeaderPreview from "./HeaderPreview";
import { Close12, Header12 } from "../../../shared/components/Icon";
import Button from "../../../shared/components/Button";
import InvoiceItems from "./InvoiceItems";
import InvoiceTotals from "./InvoiceTotals";
import { InvoiceData, InvoiceItem } from "../../../types";
import { calculateInvoiceTotals } from "../../../utils/invoiceCalculations";
import { throttle } from 'lodash';
import BaseDrawer from '../../../shared/components/BaseDrawer';

const PreviewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 2fr));
  gap: 24px;
  width: 100%;
  padding-bottom: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
`;

const TEMPLATES = [
  "minimalist",
  "modern",
  "detailed",
  "gradient",
  "boxed",
  "split",
  "circular",
  "compact",
  "structure",
] as const;

const MOCK_INVOICE_ITEMS: InvoiceItem[] = [
  {
    id: 'example-item-1',
    invoice_id: 'preview-invoice',
    description: 'Web Design Services',
    quantity: 1,
    price: 1500,
    taxable: true,
    order: 0,
  },
  {
    id: 'example-item-2',
    invoice_id: 'preview-invoice',
    description: 'Logo Design',
    quantity: 1,
    price: 500,
    taxable: true,
    order: 1,
  }
];

const MOCK_INVOICE: InvoiceData = {
  id: 'mock-id',
  client_id: 'demo-client-id',
  font: 'Inter',
  header_color: '#000000',
  header_text_color: '#ffffff',
  invoice_date: '2025-11-05',
  due_date: '2025-12-05',
  invoice_number: 'PREVIEW',
  tax_rate: 5,
  due_days: 30,
  currency: 'USD',
  items: MOCK_INVOICE_ITEMS,
  notes: '',
  invoice_template: 'minimalist',
  public_id: 'mock-public-id',
  payments: [],
  status: 'draft',
  logo_url: null,
  show_logo: true,
  invoice_created_at: '2025-12-05',
  background_color: '#ffffff',
  body_text_color: '#000000',
  subtotal: 2000,
  total: 2100,
  amount_due: 2100,
};

const PreviewCard = styled.div<{ selected: boolean; $templateType: string; $hasLogo?: boolean }>`
  box-shadow: ${props => props.selected ? '0 8px 24px rgba(0,0,0,0.2), 0 0 0 2px rgba(0,0,0,0.8)' : '0 8px 24px rgba(0,0,0,0.2)'};
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  max-width: 380px;
  background: white;
  box-sizing: border-box;
  user-select: none;
  transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  perspective: 1000;
  will-change: transform, box-shadow;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  height: ${props => {
    const hasLogo = props.$hasLogo;
    switch (props.$templateType) {
      case 'boxed': return hasLogo ? '485px' : '425px';
      case 'circular': return hasLogo ? '420px' : '290px';
      case 'compact': return hasLogo ? '405px' : '360px';
      case 'detailed': return hasLogo ? '380px' : '360px';
      case 'modern': return hasLogo ? '400px' : '375px';
      case 'minimalist': return hasLogo ? '390px' : '390px';
      case 'gradient': return hasLogo ? '388px' : '362px';
      case 'split': return hasLogo ? '340px' : '340px';
      default: return hasLogo ? '370px' : '360px';
    }
  }};
  
  &:hover {
    transform: translateZ(0) translateY(-2px);
    box-shadow: ${props => props.selected ? '0 12px 28px rgba(0,0,0,0.25), 0 0 0 2px rgba(0,0,0,0.8)' : '0 12px 28px rgba(0,0,0,0.25)'};
  }
`;

const PreviewWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
`;

const PreviewContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transform-origin: top left;
  transform: translateZ(0) scale(0.4);
  -webkit-transform-origin: top left;
  -webkit-transform: translateZ(0) scale(0.4);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  
  & > * {
    width: 250%;
  }
`;

const InvoiceContentWrapper = styled.div`
  padding: 56px;
  background-color: ${props => props.theme.backgroundColor || '#ffffff'};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  color: ${props => props.theme.bodyTextColor || '#000000'};
  margin-top: -1px;
  box-sizing: border-box;
`;

const ComponentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  user-select: none;
`;

const SelectButton = styled.button`
  width: 100%;
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s ease;
  font-family: inherit;

  svg {
    width: 12px;
    height: 12px;
    path {
      fill: rgba(0, 0, 0, 0.7);
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  &:active {
    transform: scale(0.99);
  }
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

interface HeaderStyleSelectorProps {
  selectedTemplate: string;
  onTemplateChange: (template: string) => void;
  font?: string;
  headerColor?: string;
  headerTextColor?: string;
  backgroundColor?: string;
  bodyTextColor?: string;
  logoUrl?: string | null;
  showLogo?: boolean;
}

const HeaderStyleSelector: React.FC<HeaderStyleSelectorProps> = ({
  selectedTemplate,
  onTemplateChange,
  font = "Inter",
  headerColor = "#000000",
  headerTextColor = "#ffffff",
  backgroundColor = "#ffffff",
  bodyTextColor = "#000000",
  logoUrl = null,
  showLogo = true,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = throttle(() => {
      setIsMobile(window.innerWidth <= 768);
    }, 200);

    window.addEventListener('resize', handleResize);
    return () => {
      handleResize.cancel();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const mockInvoice = useMemo(() => {
    const totals = calculateInvoiceTotals(MOCK_INVOICE);
    return {
      ...MOCK_INVOICE,
      subtotal: totals.subtotal,
      total: totals.total,
      amount_due: totals.amountDue,
    };
  }, []);

  const handleClose = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  return (
    <ComponentWrapper>
      <Label htmlFor="header-style">Header Style</Label>
      <SelectButton id="header-style" onClick={() => setIsDrawerOpen(true)}>
        <Header12 />
        <span>{selectedTemplate.charAt(0).toUpperCase() + selectedTemplate.slice(1)}</span>
      </SelectButton>

      <BaseDrawer
        isOpen={isDrawerOpen}
        onOpenChange={setIsDrawerOpen}
        onOverlayClick={handleClose}
        modal={true}
        direction={isMobile ? "bottom" : "right"}
        ignoreClickOutsideClasses={[
          'drawer-inner-content',
          'drawer-content'
        ]}
      >
        <div style={{ padding: '40px' }}>
          <DrawerHeader>
            <CloseButton onClick={handleClose}><Close12 /></CloseButton>
          </DrawerHeader>
          <PreviewGrid>
            {TEMPLATES.map((template) => (
              <PreviewContainer key={template}>
                <PreviewCard
                  selected={selectedTemplate === template}
                  $templateType={template}
                  $hasLogo={showLogo && !!logoUrl}
                  onClick={() => {
                    onTemplateChange(template);
                    setIsDrawerOpen(false);
                  }}
                >
                  <PreviewWrapper>
                    <PreviewContent>
                      <HeaderPreview
                        template={template}
                        font={font}
                        headerColor={headerColor}
                        headerTextColor={headerTextColor}
                        backgroundColor={backgroundColor}
                        bodyTextColor={bodyTextColor}
                        logoUrl={logoUrl}
                        showLogo={showLogo}
                      />
                      <InvoiceContentWrapper
                        style={{
                          backgroundColor: backgroundColor,
                          color: bodyTextColor
                        }}
                      >
                        <InvoiceItems
                          invoice={mockInvoice}
                          services={[]}
                          handleServiceSelect={() => {}}
                          handleDescriptionChange={() => {}}
                          updateItem={() => {}}
                          deleteItem={() => {}}
                          onCreateNewService={() => {}}
                          backgroundColor={backgroundColor}
                          addItem={() => {}}
                        />
                        <InvoiceTotals 
                          invoice={mockInvoice}
                          bodyTextColor={bodyTextColor}
                          backgroundColor={backgroundColor}
                        />
                      </InvoiceContentWrapper>
                    </PreviewContent>
                  </PreviewWrapper>
                  <SelectButton>
                    <Header12 />
                    Select Style
                  </SelectButton>
                </PreviewCard>
              </PreviewContainer>
            ))}
          </PreviewGrid>
        </div>
      </BaseDrawer>
    </ComponentWrapper>
  );
};

export default HeaderStyleSelector;