import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Task, Client, Project, TaskStatus, OrganizationUser } from '../../../types';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import { Close12, Client12, Project12, Calendar12, Todo12, InProgress12, Done12, More12, Person12 } from '../../../shared/components/Icon';
import { taskSchema } from '../../../schemas/task';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import DatePicker from '../../../components/DatePicker';
import EntityPicker from '../../../shared/components/EntityPicker';
import { debounce } from 'lodash';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import { createPortal } from 'react-dom';
import BaseDrawer from '../../../shared/components/BaseDrawer';
import { z } from 'zod';

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const TaskDetailsWrapper = styled.div`
  padding: 0;
  height: 100%;
  overflow-y: auto;
`;

const TaskTitle = styled.input`
  width: 100%;
  padding: 48px 48px 24px 48px;
  border: 0;
  outline: none;
  font-size: 20px;
  font-weight: 600;
  box-sizing: border-box;
  background: transparent;
`;

const PropertiesList = styled.div`
  padding: 0 40px 40px 40px;
`;

const EditorWrapper = styled.div`
  .ProseMirror {
    min-height: 100px;
    outline: none;
    padding: 24px 48px 200px 48px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    outline: none;

    p.is-editor-empty:first-child::before {
      color: rgba(0, 0, 0, 0.5);
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`;

const StatusIconWrapper = styled.div<{ $color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  svg path {
    fill: ${props => props.$color};
  }
`;

const StatusIcon = styled.svg`
  width: 12px;
  height: 12px;
  flex-shrink: 0;
`;

const MoreButtonWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 56px;
  z-index: 1;
`;

const ErrorMessage = styled.div`
  color: #ff4d4f;
  font-size: 12px;
  margin-left: 48px;
  margin-top: 4px;
`;

const ButtonFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
`;

const DeleteButton = styled(Button)`
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #ff1f1f;
  }
`;

interface TaskDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedTask: Task | null;
  updateTask: (task: Task) => void;
  updateLocalTask: (task: Task) => void;
  clients: Client[];
  projects: Project[];
  organizationUsers: OrganizationUser[];
  taskStatuses: TaskStatus[];
  onDeleteTask: (taskId: string) => void;
  autoFocus?: boolean;
  onOverlayClick?: (event: React.MouseEvent) => void;
  onPopoverOpenChange?: (isOpen: boolean) => void;
  modal?: boolean;
  ignoreClickOutsideClasses?: string[];
}

type MoreMenuItem = {
  id: string;
  name: string;
  action: () => void;
};

const TaskDrawer: React.FC<TaskDrawerProps> = ({
  isOpen,
  setIsOpen,
  selectedTask,
  updateTask,
  updateLocalTask,
  clients,
  projects,
  organizationUsers,
  taskStatuses,
  onDeleteTask,
  autoFocus,
  onPopoverOpenChange,
  modal = false,
  ignoreClickOutsideClasses = [],
}) => {
  const [localTask, setLocalTask] = useState<Task | null>(selectedTask);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const titleInputRef = useRef<HTMLInputElement>(null);
  const isEditingRef = useRef(false);

  const getCategoryIcon = (category: 'Todo' | 'In Progress' | 'Complete') => {
    switch (category) {
      case 'Todo':
        return Todo12;
      case 'In Progress':
        return InProgress12;
      case 'Complete':
        return Done12;
    }
  };

  useEffect(() => {
    if (selectedTask && !isEditingRef.current) {
      setLocalTask(selectedTask);
    }
  }, [selectedTask, isEditingRef]);

  const debouncedUpdateTask = useRef(
    debounce(
      (id: string, changes: Partial<Task>) => {
        updateTask({ ...selectedTask, ...changes, id } as Task);
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const handleInputChange = (field: keyof Task, value: string) => {
    if (!localTask) return;
    
    const updatedTask = { ...localTask, [field]: value };
    
    try {
      taskSchema.parse(updatedTask);
      setErrors({});
    } catch (error) {
      if (error instanceof z.ZodError) {
        setErrors(Object.fromEntries(
          error.errors.map(err => [err.path[0], err.message])
        ));
        return;
      }
    }
    
    setLocalTask(updatedTask);
    updateLocalTask(updatedTask);
    
    if (localTask.id) {
      debouncedUpdateTask(localTask.id, { [field]: value });
    }
  };

  const handleLocalUpdate = (field: keyof Task, value: Task[keyof Task]) => {
    if (!localTask) return;
    const updatedTask = { ...localTask, [field]: value };
    setLocalTask(updatedTask);
    updateLocalTask(updatedTask);
    
    if (localTask.id) {
      debouncedUpdateTask(localTask.id, { [field]: value });
    }
  };

  const handleDeleteTask = () => {
    if (localTask) {
      setIsDeleteModalOpen(true);
    }
  };

  const handleConfirmDelete = () => {
    if (localTask) {
      onDeleteTask(localTask.id);
      setIsDeleteModalOpen(false);
    }
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    onPopoverOpenChange?.(isAnyPopoverOpen || isDatePickerOpen);
  }, [isAnyPopoverOpen, isDatePickerOpen, onPopoverOpenChange]);

  const handleOverlayClick = useCallback((event: React.MouseEvent) => {
    if (event.target === event.currentTarget && !isAnyPopoverOpen && !isDatePickerOpen) {
      handleClose();
    }
  }, [handleClose, isAnyPopoverOpen, isDatePickerOpen]);

  useEffect(() => {
    if (isEditingRef.current) {
      const timer = setTimeout(() => {
        isEditingRef.current = false;
      }, 2500);
      return () => clearTimeout(timer);
    }
  }, [localTask]);

  useEffect(() => {
    if (autoFocus && isOpen) {
      const timer = setTimeout(() => {
        if (titleInputRef.current) {
          titleInputRef.current.focus();
          const length = titleInputRef.current.value.length;
          titleInputRef.current.setSelectionRange(length, length);
        }
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [autoFocus, isOpen]);

  const currentStatus = localTask ? taskStatuses.find(status => status.id === localTask.status_id) : null;
  const StatusIconComponent = currentStatus ? getCategoryIcon(currentStatus.category as 'Todo' | 'In Progress' | 'Complete') : Todo12;

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Add notes here...',
      }),
    ],
    content: localTask?.notes || '',
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      handleInputChange('notes', html);
    },
  });

  useEffect(() => {
    if (editor && !isEditingRef.current) {
      if (localTask?.notes !== editor.getHTML()) {
        editor.commands.setContent(localTask?.notes || '');
      }
    }
  }, [editor, localTask?.notes]);

  const moreMenuItems: MoreMenuItem[] = [
    { 
      id: 'delete', 
      name: 'Delete Task', 
      action: handleDeleteTask 
    },
  ];

  useEffect(() => {
    if (!isOpen) {
      setIsDeleteModalOpen(false);
    }
  }, [isOpen]);

  if (!localTask) {
    return null;
  }

  return (
    <BaseDrawer
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      onOverlayClick={handleOverlayClick}
      modal={modal}
      ignoreClickOutsideClasses={[
        'task-row',
        'date-picker-element',
        'entity-picker-popover',
        'drawer-inner-content',
        'drawer-content',
        ...ignoreClickOutsideClasses
      ]}
    >
      <DrawerHeader>
        <CloseButton onClick={handleClose}><Close12 /></CloseButton>
      </DrawerHeader>
      <TaskDetailsWrapper>
        <TaskTitle
          ref={titleInputRef}
          type="text"
          name="description"
          value={localTask?.description || ""}
          onChange={(e) => handleInputChange('description', e.target.value)}
          placeholder="Task description"
          style={errors.description ? { borderColor: '#ff4d4f' } : undefined}
        />
        {errors.description && (
          <ErrorMessage>
            {errors.description}
          </ErrorMessage>
        )}
        <MoreButtonWrapper>
          <EntityPicker
            selectedId={null}
            onChange={(id) => {
              const selectedItem = moreMenuItems.find(item => item.id === id);
              if (selectedItem) {
                selectedItem.action();
              }
            }}
            entities={moreMenuItems}
            label="More"
            icon={<More12 />}
            iconOnly
            placement="bottom-end"
            enableSearch={false}
            onPopoverOpenChange={setIsAnyPopoverOpen}
          />
        </MoreButtonWrapper>
        <PropertiesList>
          <DatePicker
            selectedDate={localTask?.due_date ? new Date(localTask.due_date) : null}
            onChange={(date) => handleLocalUpdate('due_date', date ? date.toISOString().split('T')[0] : null)}
            label="Due Date"
            id="task-due-date"
            onOpenChange={setIsDatePickerOpen}
            icon={<Calendar12 />}
            placement="left-start"
            showClearDate={true}
          />
          <EntityPicker
            selectedId={localTask?.status_id || null}
            onChange={(id) => handleLocalUpdate('status_id', id)}
            entities={taskStatuses.map((status) => {
              const IconComponent = getCategoryIcon(status.category as 'Todo' | 'In Progress' | 'Complete');
              return {
                id: status.id,
                name: status.name,
                color: status.color,
                icon: <IconComponent />
              };
            })}
            label="Status"
            allowUnassigned={false}
            icon={
              <StatusIconWrapper $color={currentStatus?.color || '#000000'}>
                <StatusIcon as={StatusIconComponent} />
              </StatusIconWrapper>
            }
            onPopoverOpenChange={setIsAnyPopoverOpen}
          />
          <EntityPicker
            selectedId={localTask?.client_id || null}
            onChange={(id) => handleLocalUpdate('client_id', id)}
            entities={clients?.map((client: Client) => ({
              id: client.id,
              name: client.full_name
            })) || []}
            label="Add a Client"
            allowUnassigned={true}
            icon={<Client12 />}
            onPopoverOpenChange={setIsAnyPopoverOpen}
          />
          <EntityPicker
            selectedId={localTask?.project_id || null}
            onChange={(id) => handleLocalUpdate('project_id', id)}
            entities={projects?.map((project: Project) => ({
              id: project.id,
              name: project.name || 'Untitled Project'
            })) || []}
            label="Add to Project"
            allowUnassigned={true}
            icon={<Project12 />}
            onPopoverOpenChange={setIsAnyPopoverOpen}
            placement="left-start"
          />
          <EntityPicker
            selectedId={localTask?.user_id || null}
            onChange={(id) => handleLocalUpdate('user_id', id)}
            entities={organizationUsers.map((user) => ({
              id: user.id,
              name: user.full_name || user.email || 'Unknown User'
            }))}
            label="Assign"
            allowUnassigned={true}
            icon={<Person12 />}
            onPopoverOpenChange={setIsAnyPopoverOpen}
            placement="left-start"
          />
        </PropertiesList>
        <EditorWrapper>
          <EditorContent editor={editor} />
        </EditorWrapper>
      </TaskDetailsWrapper>
      {localTask && (
        <ButtonFooter>
          <DeleteButton type="button" onClick={handleDeleteTask}>
            Delete Task
          </DeleteButton>
        </ButtonFooter>
      )}
      {createPortal(
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          title="Delete Task"
          message="Are you sure you want to delete this task? This action cannot be undone."
          confirmLabel="Delete"
          onConfirm={handleConfirmDelete}
          onCancel={() => setIsDeleteModalOpen(false)}
          isDestructive
        />,
        document.body
      )}
    </BaseDrawer>
  );
};

export default TaskDrawer;