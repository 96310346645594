import React, { useCallback, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import { Close12 } from '../../../shared/components/Icon';
import { InvoiceData, Client } from '../../../types';
import { StatusBadge } from '../../../shared/components/StatusBadge';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../../../supabaseClient';
import { toast } from 'sonner';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import EntityPicker from '../../../shared/components/EntityPicker';
import BaseDrawer from '../../../shared/components/BaseDrawer';

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const AmountAndDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 32px;
`;

const Amount = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const ActionButton = styled(Button)`
  padding: 4px 8px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  justify-content: center;
`;

const MessageEditorWrapper = styled.div`
  margin: 12px 0;

  .ProseMirror {
    width: 100%;
    min-height: 120px;
    padding: 0 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: transparent;
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    box-sizing: border-box;

    &:focus {
      border-color: rgba(0, 0, 0, 0.3);
    }

    p.is-editor-empty:first-child::before {
      color: rgba(0, 0, 0, 0.4);
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }

    > * + * {
      margin-top: 0.75em;
    }
  }
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 4px;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }
`;

const ClientSection = styled.div`
  margin: 24px 0 0 0;
`;

const TitleSection = styled.div`
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding: 40px;
`;

const DetailsContainer = styled.div<{ $status: string }>`
  display: flex;
  flex-direction: column;
  ${({ $status }) => {
    switch ($status) {
      case 'unpaid':
        return `
          background: linear-gradient(135deg, #fff4e5 0%, rgba(255,255,255,0) 30%);
        `;
      case 'paid':
        return `
          background: linear-gradient(135deg, #e6f4ea 0%, rgba(255,255,255,0) 30%);
        `;
      case 'overdue':
        return `
          background: linear-gradient(135deg, #fde7e9 0%, rgba(255,255,255,0) 30%);
        `;
      case 'draft':
        return `
          background: linear-gradient(135deg, #f1f3f5 0%, rgba(255,255,255,0) 30%);
        `;
      default:
        return `
          background: linear-gradient(135deg, #e9ecef 0%, rgba(255,255,255,0) 30%);
        `;
    }
  }}
`;

const ShareLinkContainer = styled.div`
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ShareLink = styled.div`
  color: rgba(0,0,0,0.5);
  word-break: break-all;
  padding: 4px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const ShareLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 4px;
`;

const ShareLinkButton = styled(Button)`
  background-color: rgba(255,255,255,1);
  box-shadow: 0 1px 1px rgba(0,0,0,0.1), 0 1px 4px rgba(0,0,0,0.1);
  border-radius: 6px;
`;

const InvoiceStatus = styled(StatusBadge)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 4px 10px;
  border-radius: 6px;
  width: fit-content;
  border: 2px solid;
  background: transparent;
  border-style: ${({ $status }) => $status === 'draft' ? 'dashed' : 'solid'};
  border-color: ${({ $status }) => {
    switch ($status) {
      case 'paid':
        return '#1e7e34';
      case 'unpaid':
        return '#f29d41';
      case 'overdue':
        return '#d73a49';
      case 'draft':
        return '#495057';
      default:
        return '#495057';
    }
  }};
`;

const ClientInfoRow = styled.div`
  display: flex;
  gap: 24px;
  margin: 8px 0;
`;

const ClientInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ClientLabel = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: 600;
`;

const ClientValue = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height: 28px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
`;

const EntityPickerWrapper = styled.div`
  margin: 0 0 0 -8px;
  height: 40px;
`;

interface SendInvoiceDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  invoice: InvoiceData;
  client: Client | null;
  onSendInvoice: (toastId: string | number, customMessage: string, replyToEmail: string) => void;
  onDownloadPDF: () => void;
  onOverlayClick: (event: React.MouseEvent) => void;
  onSelectClient: (clientId: string | null) => void;
  onCreateNewClient: () => void;
  onEditClient: (client: Client) => void;
  clients?: Client[];
  organizationId?: string;
}

const SendInvoiceDrawer: React.FC<SendInvoiceDrawerProps> = ({
  isOpen,
  setIsOpen,
  invoice,
  client,
  onSendInvoice,
  onDownloadPDF,
  onOverlayClick,
  onSelectClient,
  onCreateNewClient,
  onEditClient,
  clients = [],
  organizationId,
}) => {
  const [copyButtonText, setCopyButtonText] = useState('Copy Link');
  const queryClient = useQueryClient();
  
  // Fetch organization data to get the email
  const { data: orgData } = useQuery({
    queryKey: ['organization', organizationId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('organizations')
        .select('business_email')
        .eq('id', organizationId)
        .single();
      
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId && isOpen,
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Add a custom message to include in the email...',
      }),
    ],
    content: '',
  });

  // Reset editor content when drawer opens
  useEffect(() => {
    if (isOpen && editor) {
      editor.commands.setContent('');
    }
  }, [isOpen, editor]);

  // Fetch the latest invoice data
  const { data: latestInvoiceData, isLoading } = useQuery({
    queryKey: ['invoice', invoice.id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('invoices')
        .select('*')
        .eq('id', invoice.id)
        .single();
      
      if (error) throw error;
      return data as InvoiceData;
    },
    enabled: isOpen,
    staleTime: 0,
  });

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleSendInvoice = async () => {
    const toastId = toast.loading('Preparing invoice for sending...', {
      duration: Infinity
    });

    try {
      // First try to send the invoice
      const customMessage = editor?.getHTML() || '';
      const replyToEmail = orgData?.business_email || '';
      await onSendInvoice(toastId, customMessage, replyToEmail);

      // If sending was successful and invoice was a draft, update the status
      if (latestInvoiceData?.status === 'draft') {
        const { error: updateError } = await supabase
          .from('invoices')
          .update({ 
            status: 'unpaid',
            sent_at: new Date().toISOString()
          })
          .eq('id', invoice.id);

        if (updateError) {
          console.error('Error updating invoice status:', updateError);
          toast.error('Invoice sent but status update failed', { id: toastId });
        } else {
          // Invalidate queries to refresh the data
          queryClient.invalidateQueries({ queryKey: ['invoice', invoice.id] });
          queryClient.invalidateQueries({ queryKey: ['invoices'] });
        }
      }

      handleClose();
    } catch (error) {
      console.error('Error sending invoice:', error);
      toast.error('Failed to send invoice', { id: toastId });
    }
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', { 
      style: 'currency', 
      currency: latestInvoiceData?.currency || invoice.currency || 'USD' 
    }).format(amount);
  };

  const shareLink = invoice.public_id 
    ? `${window.location.origin}/share/${invoice.public_id}`
    : null;

  const handleCopyLink = async () => {
    if (!shareLink) return;

    try {
      await navigator.clipboard.writeText(shareLink);
      
      // Only update status if copy was successful and invoice was a draft
      if (latestInvoiceData?.status === 'draft') {
        const { error: updateError } = await supabase
          .from('invoices')
          .update({ 
            status: 'unpaid',
            sent_at: new Date().toISOString()
          })
          .eq('id', invoice.id);

        if (updateError) {
          console.error('Error updating invoice status:', updateError);
          toast.error('Link copied but status update failed');
        } else {
          // Invalidate queries to refresh the data
          queryClient.invalidateQueries({ queryKey: ['invoice', invoice.id] });
          queryClient.invalidateQueries({ queryKey: ['invoices'] });
          
          setCopyButtonText('Copied');
          setTimeout(() => setCopyButtonText('Copy Link'), 2000);
        }
      } else {
        setCopyButtonText('Copied');
        setTimeout(() => setCopyButtonText('Copy Link'), 2000);
      }
    } catch (error) {
      console.error('Error copying link:', error);
      toast.error('Failed to copy link');
    }
  };

  const filteredClients = useMemo(() => {
    return clients.filter(
      (client): client is Client => client !== null && client.organization_id === organizationId
    );
  }, [clients, organizationId]);

  const invoiceStatus = latestInvoiceData?.status || invoice.status;
  const background = (() => {
    switch (invoiceStatus) {
      case 'unpaid':
        return 'linear-gradient(135deg, #fff4e5 0%, rgba(255,255,255,0) 30%)';
      case 'paid':
        return 'linear-gradient(135deg, #e6f4ea 0%, rgba(255,255,255,0) 30%)';
      case 'overdue':
        return 'linear-gradient(135deg, #fde7e9 0%, rgba(255,255,255,0) 30%)';
      case 'draft':
        return 'linear-gradient(135deg, #f1f3f5 0%, rgba(255,255,255,0) 30%)';
      default:
        return 'linear-gradient(135deg, #e9ecef 0%, rgba(255,255,255,0) 30%)';
    }
  })();

  return (
    <BaseDrawer
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      onOverlayClick={onOverlayClick}
      background={background}
      modal={true}
      direction="right"
      ignoreClickOutsideClasses={[
        'invoice-row',
        'payment-row',
        'date-picker-element',
        'entity-picker-popover',
        'drawer-inner-content',
        'drawer-content'
      ]}
    >
      <DetailsContainer $status={invoiceStatus}>
        <TitleSection>
          <InvoiceStatus $status={invoiceStatus}>
            {capitalize(invoiceStatus)}
          </InvoiceStatus>
          <AmountAndDateContainer>
            {isLoading ? (
              <Amount>Loading...</Amount>
            ) : (
              <>
                <Amount>
                  Invoice for {formatCurrency(latestInvoiceData?.amount_due || invoice.amount_due)}
                </Amount>
                <Amount>
                  due on {formatDate(latestInvoiceData?.due_date || invoice.due_date)}
                </Amount>
              </>
            )}
          </AmountAndDateContainer>

          <ClientSection>
            <ClientInfoRow>
              <ClientInfoColumn>
                <ClientLabel>Client</ClientLabel>
                <EntityPickerWrapper>
                  <EntityPicker
                    selectedId={client?.id || null}
                    onChange={onSelectClient}
                    entities={filteredClients.map((client) => ({
                      id: client.id,
                      name: client.full_name || client.email || "Unknown Client",
                    }))}
                    label={client ? "Client" : "Select Client"}
                    unassignedOption="No Client"
                    allowUnassigned={true}
                    placement="left-start"
                    onCreateNew={onCreateNewClient}
                    createNewLabel="New Client"
                    onEditEntity={(entity) => {
                      const clientToEdit = filteredClients.find(c => c.id === entity.id);
                      if (clientToEdit) {
                        onEditClient(clientToEdit);
                      }
                    }}
                  /> 
                </EntityPickerWrapper>
              </ClientInfoColumn>
              <ClientInfoColumn>
                <ClientLabel>Email</ClientLabel>
                <ClientValue>
                  {client 
                    ? (
                      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                        <span>{client.email || "Add email to client to email invoice"}</span>
                      </div>
                    ) : (
                      <span>Add a client and their email to email invoice</span>
                    )
                  }
                </ClientValue>
              </ClientInfoColumn>
            </ClientInfoRow>

            {client && client.email ? (
              <>
                <DetailLabel>Custom Message</DetailLabel>
                <MessageEditorWrapper>
                  <EditorContent editor={editor} />
                </MessageEditorWrapper>
                <ButtonGroup>
                  <ActionButton buttonType="primary" onClick={handleSendInvoice} disabled={isLoading}>
                    Send Invoice
                  </ActionButton>
                  <ActionButton onClick={handleCopyLink} disabled={isLoading || !shareLink}>
                    {copyButtonText}
                  </ActionButton>
                  <ActionButton onClick={onDownloadPDF} disabled={isLoading}>
                    Download PDF
                  </ActionButton>
                </ButtonGroup>
              </>
            ) : (
              <ButtonGroup>
                <ActionButton onClick={handleCopyLink} disabled={isLoading || !shareLink}>
                  {copyButtonText}
                </ActionButton>
                <ActionButton onClick={onDownloadPDF} disabled={isLoading}>
                  Download PDF
                </ActionButton>
              </ButtonGroup>
            )}
          </ClientSection>
        </TitleSection>
        <CloseButton onClick={handleClose}><Close12 /></CloseButton>
        
        <ShareLinkContainer>
          <DetailLabel>Payment Link</DetailLabel>
          {shareLink ? (
            <ShareLinkWrapper>
              <ShareLink>
                {shareLink}
              </ShareLink>
              <ShareLinkButton onClick={handleCopyLink}>{copyButtonText}</ShareLinkButton>
            </ShareLinkWrapper>
          ) : (
            <div>No share link available</div>
          )}
        </ShareLinkContainer>
      </DetailsContainer>
    </BaseDrawer>
  );
};

export default SendInvoiceDrawer;